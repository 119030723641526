export function  getCountryCode (countryName) {
    const countryCodes = {
      'Pakistan': 'PK',
      'Canada': 'CA',
      'France': 'FR',
'United Kingdom': 'GB',
'United States Of America': 'US',
      // Add more country mappings as needed
    };
  
    // Convert the country name to title case for better matching
    const formattedCountryName = countryName.toLowerCase().replace(/\b\w/g, c => c.toUpperCase());
 
  
    // Check if the provided country name is in the mapping
    if (countryCodes.hasOwnProperty(formattedCountryName)) {
      return countryCodes[formattedCountryName];
    } else {
      // Handle the case when the country name is not found
      console.warn(`Country code not found for ${formattedCountryName}`);
      return null;
    }
  };