import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { EncryptionAndDecryptionKeys } from '../../../model/url';
import { decryptMydata } from '../../../Provider/EncriptionAndDecription';

function LeavePolicy() {
    const [selectedCity, setSelectedCity] = useState(null);
    const [secondVisible, SetsecondVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const organization = useSelector((state) => state.or.or);
    const key = EncryptionAndDecryptionKeys();
    const decriptedOrganization = JSON.parse(decryptMydata(organization, key)) 
    useEffect(() => {
        // Check if there are any changes in selectedCity
        setSelectedCity(decriptedOrganization.LeavesAllowed);
        if (selectedCity !== decriptedOrganization.LeavesAllowed) {
            SetsecondVisible(true);
        } else {
            SetsecondVisible(false);
        }
    }, [selectedCity]);
    const loadforSecond = async () => {
        
       
    };
    const cities = [
        { name: 'No Leave Allowed', code: '0' },
        { name: '1', code: '1' },
        { name: '2', code: '2' },
        { name: '3', code: '3' },
        { name: '4', code: '4' },
        { name: '5', code: '5' },
        { name: '6', code: '6' },
        { name: '7', code: '7' },
        { name: '8', code: '8' },
    ];
  return (
    <div className='mb-2'>
        <div className='bg-white w-90% p-4 shadow rounded-lg text-black'>
        Leave Allowed
        <div className='p-5 flex justify-space'>
        <label htmlFor="Check IN Time" className="font-bold block">
                    Leave allowed to all employees per month
                </label>
        <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name" 
                showClear placeholder="Select leave allowed" className="w-full md:w-14rem" defaultValue={decriptedOrganization.LeavesAllowed} />

        </div>
        {
                secondVisible ? <Button label="Update" icon="pi pi-check" loading={loading} onClick={loadforSecond} /> : null
            }

        </div>
      
    </div>
  )
}

export default LeavePolicy
