import React, { useState, useEffect, useRef } from 'react';
import Box from '../components/dashbaord/Box';
import Chart from '../components/dashbaord/Chart';
import LeavesContent from '../components/leave/leaves';
import processEmployeeData from '../Provider/DapertmentNameAndStrength'
import AttendanceNewTable from '../components/attendence/attendanceNewTable';
import {AnalyzeAttendanceData} from '../Provider/AnalyzeAttendance';
import {combineLeaveDataWithEmployeeData} from '../Provider/AnalyzeLeave';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Birthday from '../components/Birthday';
import Holidays from '../components/Holidays';
import NewUser from '../components/dashbaord/NewUser';
import EmptyData from '../components/dashbaord/EmptyData';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import AddHolidayPopup from '../components/dashbaord/AddHolidayPopup';

export default function Dashboard({employee, attendance, leave, status, organization, isPlatinum, isStartup}) {

  // Assuming attendance is an array of objects
// attendance.forEach((item, index) => {
//   console.log(`Attendance ${index + 1}:`, item);
// });

  const toast = useRef(null);
  const [isMobiletMode, setisMobiletMode] = useState(false);
  const [isTabletMode, setisTabletMode] = useState(false);
  const [DepartmentName, SetDepartmentName] = useState([]);
  const [DepartmentStrength, SetDepartmentStrength] = useState([]);
  const [NumberOfEmployees, SetNumberOfEmployees] = useState();
  const [absentCount, SetAbsentCount] = useState(0);
  const [presentCount, SetPresentCount] = useState(0);
  const [combinedData, SetCombineData] = useState();
  const [approvedCount, SetapprovedCount] = useState();
  const [rejectedCount,SetRejectedCount]=useState();
  const[totalLeaves,setTotalLeaves]=useState();
  const [pendingCount, SetpendingCount]=useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);
  const [mydate, setDate] = useState(null);
  const [isPopupOpen, setPopupOpen] = useState(false);


  // console.log('attendance is: '+attendance)
  




  // show custom setting
  const [show_payroll, Set_show_payroll] = useState(null);
  const [show_leave, Set_show_leave] = useState(null);
  const [show_attendance, Set_show_attendance] = useState(null);
  const [show_total_employees, Set_show_total_employees] = useState(null);



  const getCustomSetting = () =>{
    if (organization && organization.custom_settings) {
      const customSettings = organization.custom_settings;

      // Parse custom settings and set state values accordingly
      Set_show_payroll(customSettings.show_payroll === 'true');
      Set_show_leave(customSettings.show_leave === 'true');
      Set_show_attendance(customSettings.show_attendance === 'true');
      Set_show_total_employees(customSettings.show_total_employees === 'true');
    }
  }


  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const accept = () => {
    toast.current.show({ severity: 'success', summary: 'Holiday Added', detail: 'Holiday has been added and informed to all employees', life: 3000 });
};



  


  const handleLoginClick = () => { 
    // Add logic to navigate to the login page or perform any other action
    setShowAlert(false);
    navigate("/login"); // Hide the alert after login
  };



  useEffect(() => {
    // Set the timer to clear Redux Persist and navigate to /login
    
    const timer = setTimeout(() => {
      // dispatch(clearPersistedData());
      // localStorage.removeItem('token');



      //show alert to navigate

    }, 30 * 60 * 1000); 

    return () => clearTimeout(timer);
  }, [dispatch, navigate]);


// console.log(employee.employees)

  

  function generateRandomColors(numColors) {
  
    const colorClasses = [
      '--red-400', '--red-500', '--red-600', '--red-700', '--red-800', '--red-900',
      '--yellow-400', '--yellow-500', '--yellow-600', '--yellow-700', '--yellow-800', '--yellow-900',
      '--green-400', '--green-500', '--green-600', '--green-700', '--green-800', '--green-900',
      '--blue-400', '--blue-500', '--blue-600', '--blue-700', '--blue-800', '--blue-900',
      '--indigo-400', '--indigo-500', '--indigo-600', '--indigo-700', '--indigo-800', '--indigo-900',
      '--pink-400', '--pink-500', '--pink-600', '--pink-700', '--pink-800', '--pink-900'
    ];
  
    const colors = [];
  
    for (let i = 0; i < numColors; i++) {
      const randomIndex = Math.floor(Math.random() * colorClasses.length);
      colors.push(colorClasses[randomIndex]);
    }
  
    return colors;
  }
  
  

  const totalEmployeesLabels = DepartmentName;
  const totalEmployeesData = DepartmentStrength;
  const numDepartments = totalEmployeesLabels.length;

  const customColors = [
     '--red-500', '--green-500',  '--yellow-500', '--blue-500', '--indigo-500', '--pink-500','--red-700', '--red-800', '--red-900',
    '--yellow-400',  '--yellow-600', '--yellow-700', '--yellow-800', '--yellow-900',
    '--green-400', '--green-600', '--green-700', '--green-800', '--green-900',
    '--blue-400',  '--blue-600', '--blue-700', '--blue-800', '--blue-900',
    '--indigo-400',  '--indigo-600', '--indigo-700', '--indigo-800', '--indigo-900',
    '--pink-400',  '--pink-600', '--pink-700', '--pink-800', '--pink-900','--red-600','--red-400',
  ]
 

  const attendanceOverviewLabels = ['Present', 'Absent'];
  const attendanceOverviewData = [presentCount, absentCount];
  const customColorsforAttendance = [
    '--indigo-500',
    '--red-500',
  ]

  const leaveRequestsLabels = ['Pending', 'Approved', 'Rejected'];
  const leaveRequestsData = [pendingCount, approvedCount, rejectedCount];
  const leaveData = isPlatinum && !show_leave ? [1,1,1] : leaveRequestsData;
  const customColorsforLeave = [
    '--yellow-500',
    '--indigo-500',
    '--red-500',
  ]

  const payrollStatusLabels = ['Processing', 'Completed', 'Upcoming'];
  const payrollStatusData = [1, 1, 1];
  const customColorsforPayroll = [
    '--yellow-500',
    '--indigo-500',
    '--red-500',
  ]

  ;

  

  const getEmployeesData =  () =>{
    const employeeDataArray = employee;

    // Check if employeeDataArray is an array
    if (!Array.isArray(employeeDataArray)) {
  // console.error('Employee data is not an array:', employeeDataArray);
    // Handle the error in a way that makes sense for your application
    } else {
    const processedData = processEmployeeData(employeeDataArray);
    const departmentArray = processedData.departments;
    const departmentNames = departmentArray.map((department) => department.name);
    const departmentStrength = departmentArray.map((department) => department.strength);
    const analyzedAttendance =  AnalyzeAttendanceData(attendance);
    // console.log(analyzedAttendance)

    
  
    const { combinedData, approvedCount, rejectedCount, pendingCount } = combineLeaveDataWithEmployeeData(leave, employee);
    SetCombineData(combinedData);
    SetapprovedCount(approvedCount);
    SetRejectedCount(rejectedCount);
    SetpendingCount(pendingCount);
    setTotalLeaves(approvedCount+rejectedCount+pendingCount);
    SetAbsentCount(analyzedAttendance.absentCount);
    SetPresentCount(analyzedAttendance.presentCount);
    // console.log(analyzedAttendance.presentCount)
    SetDepartmentName(departmentNames); 
    SetDepartmentStrength(departmentStrength);
    }
  } 
   

  const handleResize = () => {
    setisMobiletMode(window.innerWidth <= 425); 
  };
  const handleResizeforTabletMode = () => {
    setisTabletMode(window.innerWidth <= 768); 
  };
  useEffect(() => {
    handleResize(); 
    getEmployeesData();
    SetNumberOfEmployees(employee.length);
    handleResizeforTabletMode();
    if (isPlatinum) {
      getCustomSetting();
    }
   
    window.addEventListener('resize', handleResize);
    window.addEventListener('resize', handleResizeforTabletMode);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('resize', handleResizeforTabletMode);
    }; 
  }, []);
  return (
    <div className=''>
      <Toast ref={toast} />
      

      {isNewUser ? ( 
        <NewUser/>
      ):     (
        <>
        {status === false ? (
        <div>
          <EmptyData organization={organization}/>

        </div>) : (
          <div>
            <div className='font-bold mb-2'>Dashboard</div>
                {
                  isMobiletMode && <div>
                    <div className='flex space-x-4'>
                      {
                        isPlatinum && show_total_employees && (<Box number={NumberOfEmployees} chartComponent={<Chart tittle={"Total Employees"}/>} isMobiletMode={isMobiletMode} />)
                      }
                  {
                    isStartup && (
                      <Box number={NumberOfEmployees} chartComponent={<Chart tittle={"Total Employees"}/>} isMobiletMode={isMobiletMode} />
                    )
                  }
                  {
                        isPlatinum && show_attendance && (<Box number={456} chartComponent={<Chart tittle={"Attendance Overview"}/>} isMobiletMode={isMobiletMode}/>)
                      }

{
                    isStartup && (
                      <Box number={456} chartComponent={<Chart tittle={"Attendance Overview"}/>} isMobiletMode={isMobiletMode}/>
                    )
                  }

                 
                </div>
                <div className='m-4'></div>
                <div className='flex space-x-4'>
                {
                        isPlatinum && show_leave && (<Box number={totalLeaves} chartComponent={<Chart tittle={"Leave Requests"}/>} isMobiletMode={isMobiletMode}/>)
                      }
                      {
                    isStartup && (
                      <Box number={totalLeaves} chartComponent={<Chart tittle={"Leave Requests"}/>} isMobiletMode={isMobiletMode}/>
                    )
                  }

{
                        isPlatinum && show_payroll && (<Box number={101} chartComponent={<Chart tittle={"Payroll Status"}/>} isMobiletMode={isMobiletMode}/>)
                      }
                      {
                    isStartup && (
                      <Box number={101} chartComponent={<Chart tittle={"Payroll Status"}/>} isMobiletMode={isMobiletMode}/>
                    )
                  }
                 
                 
                </div>
                    </div>
                }
                  {
                  !isMobiletMode && <div className=''>
                    <div className='flex space-x-4 '>
                  {
                    isPlatinum && show_total_employees && (
                      <Box number={NumberOfEmployees} chartComponent={<Chart tittle={"Total Employees"}/>} isMobiletMode={isMobiletMode} isTabletMode={isTabletMode} labels={totalEmployeesLabels} dataProp={totalEmployeesData} customColors={customColors}/>
                    )
                  }
                  {
                    isStartup && (
                      <Box number={NumberOfEmployees} chartComponent={<Chart tittle={"Total Employees"}/>} isMobiletMode={isMobiletMode} isTabletMode={isTabletMode} labels={totalEmployeesLabels} dataProp={totalEmployeesData} customColors={customColors}/>
                    )
                  }
                 {
                   isPlatinum && show_attendance && (
                    <Box number={`${absentCount}/${presentCount}`} chartComponent={<Chart tittle={"Attendance Overview"}/>} isMobiletMode={isMobiletMode} isTabletMode={isTabletMode} labels={attendanceOverviewLabels} dataProp={attendanceOverviewData} customColors={customColorsforAttendance}/>
                   )
                 }
                 {
                  isStartup && (
                    <Box number={`${absentCount}/${presentCount}`} chartComponent={<Chart tittle={"Attendance Overview"}/>} isMobiletMode={isMobiletMode} isTabletMode={isTabletMode} labels={attendanceOverviewLabels} dataProp={attendanceOverviewData} customColors={customColorsforAttendance}/>
                  )
                 }

                  <Box number={totalLeaves} chartComponent={<Chart tittle={"Leave Requests"}/>} isMobiletMode={isMobiletMode} isTabletMode={isTabletMode} labels={leaveRequestsLabels} dataProp={leaveData} customColors={customColorsforLeave}/>
                  <Box number={0} chartComponent={<Chart tittle={"Payroll Status"}/>} isMobiletMode={isMobiletMode} isTabletMode={isTabletMode} labels={payrollStatusLabels} dataProp={payrollStatusData} customColors={customColorsforPayroll}/>
                
                  
                 
                  
                </div>
                    </div>
                }
                <div className='flex flex-col mt-4 gap-2 h-auto md:flex-row md:gap-2'>
            <div className='w-full md:w-1/3 bg-white p-4 shadow rounded-lg'>
              <Birthday employee={employee}/>
            </div>

            {
              !isPlatinum && (
                <div className='w-full md:w-1/3 bg-white p-4 shadow rounded-lg'>
              <Holidays organizationCountry={organization.Country}/>
            </div>
              )
            }
            
            <div className='w-full md:w-1/3 flex justify-content-center bg-white p-4 shadow rounded-lg'>
              <Calendar  onClick={!show_leave ? null : openPopup} dateFormat='DD d MMM yyyy' inline className='w-full' value={mydate} onChange={(e) => setDate(e.value)}/>
            </div>
          </div>
          {/* <AttendanceNewTable attendance={attendance} length={3} /> */}
        
        
        
         {/* {
           isPlatinum && show_leave
         } */}
         {isPlatinum && show_leave && (
  <div className='mt-4'>
    <LeavesContent leave={combinedData} />
  </div>
)}

{
  isStartup && (
<div className='mt-4'>
          <LeavesContent leave={combinedData}/>
          </div>
  )
}

          
         
          {/* {attendance === "" ? (
  <div className='mt-4'>
    <AttendanceNewTable attendance={attendance} length={3} />
  </div>
) : (
  <div></div>
)} */}

{/* {combinedData === "" ? (
  <div className='mt-4'>
    <LeavesContent leave={combinedData}/>
  </div>
) : (
  <div></div>
)} */}

                
                {/* <div className='mt-4'>
                  <LeavesContent leave={combinedData}/>
                </div> */}
                  </div>
        )}
        </>
        // {status === false ? (<div></div>) : (<div></div>)}

//         
      )}
      {isPopupOpen && <AddHolidayPopup onClose={closePopup} date={mydate}/>} 
      
    </div>
    
  );
}
