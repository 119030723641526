import React, {useState, useEffect} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import AddManagerPopUp from './AddManagerPopUp';
import { getAllLocations } from '../../../Provider/returnLocations';
import { Tag } from 'primereact/tag';
import { Avatar } from 'primereact/avatar';
import { CheckImageOrLabelForEmployeeTable } from '../../../Provider/checkImage';
import { CheckImageOrLabelForManager } from '../../../Provider/checkImage';

function SubAdmin({Locations, Managers, employees, organization}) {
    const officePremises = getAllLocations(Locations);
    const [isPopupOpen, setPopupOpen] = useState(false);
    // console.log(Managers)

  
    const openPopup = () => {
        setPopupOpen(true);
      };
    
      const closePopup = () => {
        setPopupOpen(false);
      };



      //body templates
      const statusItemTemplate = (rowData) => {
        return <Tag value={rowData.location}  />;
    };

   

  const employeeBodyTemplate = (rowData) =>{
    // console.log(rowData)
    const name = CheckImageOrLabelForManager(rowData)
    return (
        <div className='flex items-center'>
           <Avatar 
            label={name}
            style={{ backgroundColor: '#32317f', color: '#ffffff' }}
            className="mr-2" size="large" shape="circle" />
            <div className="flex flex-col">
                <span className='text-black'>{rowData.name}</span>
                <span className="text-indigo-500 text-xs">{rowData.email}</span>
            </div>
        </div>
    );

}
  return (
    <div>
      
      <div className='text-black bg-white w-90% p-4 shadow rounded-lg mt-5 mb-5 '>
      My Managers
      <div>
      <div className="flex justify-end mb-4">
        <Button label="Add Manager" onClick={openPopup}/>
      </div>
      <div className="m-0 bg-white p-4 shadow rounded-lg mt-1">
            <DataTable value={Managers} removableSort  dataKey='id' selectionMode="single"   filterDisplay="row" size={'small'} 
                    globalFilterFields={['name', 'email',]}  emptyMessage="No Managers found 😔">
               
                <Column  header="Name" style={{ minWidth: '12rem' }}  body={employeeBodyTemplate}/>
               
                <Column  header="Location"  style={{ minWidth: '12rem' }} body={statusItemTemplate}/>
                <Column field='address' header="Address"  style={{ minWidth: '12rem' }}/>
            </DataTable>
        </div>

      </div>

      </div>
      {isPopupOpen && <AddManagerPopUp onClose={closePopup} locations={officePremises} employees={employees} organization={organization}/>} 
    </div>
  )
}

export default SubAdmin
