import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import React, { useState } from 'react';
import AddPoliciesPopup from './AddPoliciesPopup';

function OrganizationPolicy({ organization }) {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  return (
    <div className='mb-2'>
      <div className='bg-white w-90% p-4 shadow rounded-lg text-black'>
        Organization Policies
        <div className='p-5 space-y-5'>
          <Button label="Add New" icon="pi pi-plus" onClick={openPopup} />
          {organization.Policies !== 0 ? (
            <div className='mt-5 space-y-5'>
            {organization.Policies.map((policy, index) => (
              <Fieldset key={index} legend={policy.Title} toggleable>
                <p className="m-0">{policy.Description}</p>
              </Fieldset>
            ))}
          </div>
          ) : (
            <div>
                </div>
          )}
          
          {isPopupOpen && <AddPoliciesPopup onClose={closePopup} />}
        </div>
      </div>
    </div>
  );
}

export default OrganizationPolicy;
