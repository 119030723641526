import { inviteEmployeeUrl } from "../model/url";

export async function inviteEmployee ({data}) {
    const url = inviteEmployeeUrl();

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });


      if (response.ok) {
        const mydata = await response.json();
        const status = mydata["statusCode"];
        if (status === 200) {
            return true;
        }else{
            return false;

        }
        
        
      } 
    } catch (error) {
      console.error('Error fetching details:', error);
      return false;
    } 
  };