import CryptoJS from 'crypto-js';

export function encriptmydata(data, Keys) {
  const sensitiveData = JSON.stringify(data);

  if (sensitiveData.length === 0 || Keys.length === 0) {
    return "Error: Empty input data or secret key";
  }

  const encryptedData = CryptoJS.AES.encrypt(sensitiveData, Keys).toString();
  return encryptedData;
}

export function decryptMydata(data, Keys) {
  if (!data) {
    return "";
  }
  const decryptedData = CryptoJS.AES.decrypt(data, Keys).toString(CryptoJS.enc.Utf8);
  JSON.stringify(decryptedData).toString();
  return decryptedData;
  
}
// function encryptData(data, secretKey) {
//   const key = CryptoJS.enc.Utf8.parse(secretKey);
//   const encrypted = CryptoJS.AES.encrypt(data, key, {
//     mode: CryptoJS.mode.ECB,
//     padding: CryptoJS.pad.Pkcs7,
//   });
//   return encrypted.toString();
// }

// // Decryption function
// function decryptData(encryptedData, secretKey) {
//   const key = CryptoJS.enc.Utf8.parse(secretKey);
//   const decrypted = CryptoJS.AES.decrypt(encryptedData, key, {
//     mode: CryptoJS.mode.ECB,
//     padding: CryptoJS.pad.Pkcs7,
//   });
//   return decrypted.toString(CryptoJS.enc.Utf8);
// }

// function generateKey() {
//     const crypto = require('crypto');
// const secretKey = crypto.randomBytes(32).toString('hex');
// console.log(secretKey);
// }

// export { encryptData, decryptData, generateKey };
