import { LuCalendarX2 } from "react-icons/lu";
import { LuFingerprint } from "react-icons/lu";
import { LuUsers } from "react-icons/lu";
import { LuLayoutGrid } from "react-icons/lu";
import { LuSettings } from "react-icons/lu";
import { LuDollarSign } from "react-icons/lu";
import { BsPhoneFlip } from "react-icons/bs";



export const datas = [
    {
      id: 1,
      to:"/",
      icon: <LuLayoutGrid />,
      text: "Dashboard",
    },
    {
      id: 2,
      to:"/employees",
      icon: <LuUsers />,
      text: "Employees",
    },
    {
      id: 3,
      to:"/attendance",
      icon: <LuFingerprint />,
      text: "Attendence",
    },
    {
      id: 4,
      to:"/leaves",
      icon: <LuCalendarX2 />,
      text: "Leave",
    },
    {
      id: 5,
      to:"/payroll",
      icon: <LuDollarSign />,
      text: "Payroll",
    },
    {
      id: 6,
      to:"/device",
      icon: <BsPhoneFlip />,
      text: "Devices",
    },
  ];

  export const LastTwo = [
    {
      id: 5,
      to:"/setting",
      icon: <LuSettings />,
      text: "Setting",
    },
    // {
    //   id: 6,
    //   to:"/support",
    //   icon: <LuHelpCircle />,
    //   text: "Support",
    // },
  ];