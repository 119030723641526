// actions.js
export const clearPersistedData = () => {
  // console.log("at clearing function");
    return {
      type: 'CLEAR_PERSISTED_DATA',
    };
  };

  // const removePersistData = (state, action) => {
  //   if (action.type === 'CLEAR_PERSISTED_DATA') {
  //     storage.removeItem('persist:root')
  //     // Clear the persisted data
  //     return undefined;
  //   }
  
  //   // Your other reducer logic here
  //   return state;
  // };
  
  // export default removePersistData;