import React, { useState } from 'react';
import { AiOutlineEyeInvisible } from 'react-icons/ai';
import { FiEye } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../Data/IMG/tt.png';
import { encriptmydata } from '../Provider/EncriptionAndDecription';
import { removeLatlngOfLocation } from '../Provider/cleanOrganizationData';
import { AttendenceApiDataParsing, EmployeesApiDataParsing, LeaveApiDataParsing } from '../Provider/startApiData';
import { createResponseModel } from '../model/Organization';
import { EncryptionAndDecryptionKeys, OrganizationUrl } from '../model/url';

function Login() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showError, SetshowError]= useState(false);
  const [Error, SetError] = useState();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const dispatch = useDispatch();

  const moveToSignUp = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = 'https://tokentalent.co/signup';

    // Open the URL in a new tab
    window.open(urlToOpen, '_blank');
  };  const moveToTermsAndConditions = () => {
    // Specify the URL you want to open in a new tab
    const urlToOpen = 'https://tokentalent.co/termsandCondition';

    // Open the URL in a new tab
    window.open(urlToOpen, '_blank');
  };


  const MoveTODashboard = async (e) => {
    e.preventDefault();
    const key = EncryptionAndDecryptionKeys();
    const url = OrganizationUrl(email, password);
    setLoading(true);
    const response = await fetch(url, {
      method: 'GET',
    });
    const res = await response.json();
    const StatusCode = res.statusCode;
    const responseBody = res.body;

    if (StatusCode !== 200) {
      SetshowError(true);
    }else{
      // return
    }

    // console.log(res.data);
   
  
    if (responseBody === "authenticated") {
      const responseModel = createResponseModel(res);
      const arrayWithOutLocations = removeLatlngOfLocation(res.data);
      
      const encriptedOrganzationData = encriptmydata(arrayWithOutLocations, key);   
      // console.log(encriptedOrganzationData, "<======= this is encripted data at login js")
      dispatch({ type: 'SET_ORGANIZATION_DATA', payload: encriptedOrganzationData });
      setLoading(false);
      const token = res.data['Organization ID'];
      // console.log(token)

      const EmployeeStatus = await EmployeesApiDataParsing(token);
      const encryptEmployeeData = encriptmydata(EmployeeStatus, key);
      // console.log(EmployeeStatus)
      if (EmployeeStatus !== false) {
        dispatch({ type: 'SET_EMPLOYEE_DATA', payload: encryptEmployeeData });
       
      } else {
        console.log("some error accors")
      }
      const AttendanceStatus = await AttendenceApiDataParsing(token);
      if (AttendanceStatus !=="error" )
      {
        const encryptAttendanceStatus = encriptmydata(AttendanceStatus, key);
        dispatch({type:'SET_ATTENDANCE_DATA' ,payload : encryptAttendanceStatus})
        }else{
          console.log('attendance api call failed')
          }
      const LeaveStatus = await LeaveApiDataParsing(token);
      if(LeaveStatus!=='error'){
        const encryptLeaveStatus = encriptmydata(LeaveStatus, key);
        dispatch ({type:"SET_LEAVE_DATA",payload:encryptLeaveStatus});
        }else{
          console.log('attendance api call failed')
          }

          

      // console.log(token)
      const encriptedToken = encriptmydata(token, key);
      localStorage.setItem('token', encriptedToken);
      navigate("/");
    
      
      
      return false;
    } else {
      SetError(responseBody);
      setLoading(false); 
      return false;
      
    }



  
  };

  const navigate = useNavigate();




  return (
    <div
    className="h-screen overflow-y-hidden"
    style={{ background: 'linear-gradient(to top, #011549, #021C5E)' }}
  >
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 pt-[0rem] md:pt-[0rem] md:py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm flex-col justify-center">
        <Link
          to="/"
          className="flex items-center text-center text-indigo-900 justify-center  rounded-full"
        >
          <img src={Logo} className="h-10 mr-3 " alt="Token Talent Logo" />
        </Link>
        <h2 className="mt-10 text-center text-4xl md:text-4xl font-bold leading-9 tracking-tight text-white">
          Token Talent
        </h2>
      </div>

      <div className="px-5 py-8 rounded-xl bg-white mt-[1.5rem] sm:mx-auto sm:w-full sm:max-w-sm ">
        <form className="space-y-6" onSubmit={MoveTODashboard}>
          <div>
            <label
              htmlFor="email"
              className="block text-xl md:text-sm font-medium leading-6 text-indigo-900"
            >
            </label>
            <div className="mt-2">
              <input
                placeholder="Email"
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full border border-gray-300 rounded-md py-3 px-3 md:py-3 md:px-3  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-xl md:text-sm font-medium leading-6 text-indigo-900"
              >
               
              </label>
            </div>
            <div className="mt-2 relative">
              <input
                placeholder="Password"
                id="password"
                name="password"
                type={passwordVisible ? 'text' : 'password'}
                autoComplete="current-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border border-gray-300 py-3 px-3 md:py-3 md:px-3  text-gray-900 shadow-sm ring-1 ring-inset ring-indigo-300 placeholder:text-gray-400   sm:text-sm sm:leading-6"
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                onClick={togglePasswordVisibility}
              >
                {passwordVisible ? (
                  <FiEye className="h-10 w-5 text-gray-400" />
                ) : (
                  <AiOutlineEyeInvisible className="h-10 w-6 text-gray-400" />
                )}
              </div>
            </div>
          </div>
          <p className="text-xs ml-1 text-gray-500">
            By clicking Login, I accept the{' '}
            <Link to="" onClick={moveToTermsAndConditions} className="hover:underline">
              Terms of Use.
            </Link>
          </p>
          <div>
            <div className="flex items-center justify-center space-x-4">
              <button
                className={`transition-all duration-400 ease-in-out  ${
                  loading
                    ? 'w-14 h-14 bg-indigo-900 text-white rounded-full'
                    : 'flex w-full justify-center rounded-md bg-indigo-900 px-3 py-3 md:py-3 text-base md:text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:none'
                } hover:bg-indigo-700`}
                // onClick={MoveTODashboard}
                disabled={loading}
              >
                {loading ? (
                  <div className=" animate-spin flex items-center justify-center ">
                    <svg
                      className="w-6 h-6 text-white "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                     
                  <circle
                    className='opacity-25 '
                    cx='12'
                    cy='12'
                    r='10'
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>

                    </svg>
                  </div>
                ) : (
                  'Login'
                )}
              </button>
            </div>
          </div>
        </form>
        {showError ? (<div className='text-xs text-red-500 mt-2  text-center'>{Error}</div>):(<div></div>)}

        <p className="mt-10 text-center text-base md:text-sm text-gray-500">
          Don't have an account yet?&nbsp;
          <Link
            to="/signup"
            onClick={moveToSignUp}
            className="font-semibold leading-6 text-indigo-900 hover:text-black"
          >
            Sign Up Now
          </Link>
        </p>
      </div>
      {/* <div className="px-3 py-2 rounded-xl rounded-t-none bg-gray-300 mt-[rem] sm:mx-auto  sm:w-[90%] md:w-[50%] lg:w-[35%] xl:w-[25%]"></div> */}
    </div>
  </div>
  );
}

export default Login;


