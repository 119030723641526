export function calculateBirthday(employeeDataArray) {
    // Initialize an array to store the results
  const anniversariesWithinNext7Days = [];
  // console.log(employeeDataArray)

  // Iterate through each employee data object
  for (const employeeData of employeeDataArray) {
    const { Name, DateofBirth, Photo } = employeeData;

    // Convert JoiningDate to a JavaScript Date object
    const joiningDate = new Date(DateofBirth);

    // Calculate the current date
    const currentDate = new Date();

    // Calculate the work anniversary date for this year
    const workAnniversaryDate = new Date(
      currentDate.getFullYear(),
      joiningDate.getMonth(),
      joiningDate.getDate()
    );

    // Calculate the number of days remaining
    const timeDifference = workAnniversaryDate - currentDate;
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    // Check if the work anniversary is within the next 7 days
    if (daysRemaining >= 0 && daysRemaining <= 7) {
      anniversariesWithinNext7Days.push({
        Name,
        Photo,
        DaysRemaining: daysRemaining,
      });
    }
  }

  return anniversariesWithinNext7Days;
  }
  