export function EmployeeDeviceInfo(devices, employees) {
    let employeeDataArray = [];
  
    devices.forEach(device => {
      const employeeId = device.employee_id;
      const matchingEmployee = employees.find(employee => employee['Employee ID'] === employeeId);
  
      if (matchingEmployee) {
        const employeeData = {   
          name: matchingEmployee.Name,
          email: matchingEmployee.Email,
          office: matchingEmployee.Office,
          photo: matchingEmployee.Photo,
          phone: matchingEmployee.Phone,
          date: device.date,
          employeeId: device.employee_id,
          department: device.department,
          newDeviceId: device.new_device_info[0].id,
          newDeviceBrand: device.new_device_info[0].brand,
          newDeviceModel: device.new_device_info[0].model,
          newDeviceToken: device.new_device_info[0].token ?? "",
  
          oldDeviceId: device.old_device_info[0].id,
          oldDeviceBrand: device.old_device_info[0].brand,
          oldDeviceModel: device.old_device_info[0].model,
          oldDeviceToken: device.old_device_info[0].token?? "",
          status: 'Pending'
        };
  
        employeeDataArray.push(employeeData);
      }
    });
  
    return employeeDataArray;
  }
  