// store.js
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import rootReducer from './rootReducer';
import {clearPersistedData} from './action';


const persistConfig = {
    key: 'ro',
    storage,
    whitelist: ['or', 'em', 'at', 'le'], // Reducer(s) to persist
  };

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
const persistor = persistStore(store);
// persistor.subscribe(() => {
//   const state = store.getState();
//   if (!state.user || Object.keys(state.user).length === 0) {
//     // If user data is missing or empty, clear Redux Persist data
//     store.dispatch(clearPersistedData());
//   }
// });


export { store, persistor };
 