export function removeLatlngOfLocation(mydata) {
    const newData = { ...mydata };
    const locations = newData.Locations;
    
    if (locations && locations.length > 0) {
      newData.Locations = locations.map((location) => {
        const { data, ...locationWithoutLatlng } = location;
        return locationWithoutLatlng;
      });
    }
    
    return newData;
  }