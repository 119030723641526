export const EmployementTypeOptions = [
    { label: 'Full-time', value: 'Full-time' },
    { label: 'Part-time', value: 'Part-time' },
    { label: 'Contractual', value: 'Contractual' },
    { label: 'Internship', value: 'Internship' },
  ];

  export const GenderTypeOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];
  export const EmployeeExitTypeOptions = [
    { label: 'Voluntary Resignation/Quit', value: 'Voluntary Resignation/Quit' },
    { label: 'Retirement', value: 'Retirement' },
    { label: 'Termination/Fired', value: 'Termination/Fired' },
    { label: 'Layoff/Redundancy', value: 'Layoff/Redundancy' },
    { label: 'End of Contract/Termination of Contract', value: 'End of Contract/Termination of Contract' },
    { label: 'Mutual Agreement/Resignation by Mutual Consent', value: 'Mutual Agreement/Resignation by Mutual Consent' },
    { label: 'Health Reasons/Medical Leave', value: 'Health Reasons/Medical Leave' },
    { label: 'Maternity/Paternity Leave', value: 'Maternity/Paternity Leave' },
    { label: 'Career Change', value: 'Career Change' },
    { label: 'Job Abandonment', value: 'Job Abandonment' },
    { label: 'Relocation', value: 'Relocation' },
    { label: 'Death', value: 'Death' },
  ];
  