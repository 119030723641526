import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import React, { useRef, useState, useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { IoIosCloseCircle } from 'react-icons/io';
import { AddManagerAsSubAdmin } from '../../../Provider/AddSubAdminAsManager';



function AddManagerPopUp({onClose, locations,  employees, organization}) {

    const toast = useRef(null);
    const [mylocation, setLocation] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedEmployee, setselectedEmployee] = useState();
    const [EmployeeEmail, setEmployeeEmail] = useState();
    const [Employeeid, setEmployeeid] = useState();
    const [Employeename, setEmployeename] = useState();
    const [Employeepassword, setEmployeepassword] = useState();
    const [EmployeeImage, setEmployeeImage] = useState();

    const showFilterforEmployee = employees.length >5 ? true : false;
    const showFilterforLocations = locations.length >5 ? true : false;
    const oid = organization['Organization ID']


   
    


   

  





    

    

    const handleEmployeeChange = (e) => {
      setselectedEmployee(e.value);
      
    }; 

    

    const handleLocationChange = (e) => {
        setLocation(e.value);
      }; 

    const showSuccess = () => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Manager has been added',
          life: 3000,
        });
    
    
       
    
        
    
        
        
    
        // Clear text field data
       setselectedEmployee('');
        setLocation('');
        
      };
      const showFailure = () => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went Wrong while adding! Try again leter',
          life: 3000,
        });
      }

      const setEmployeeDetails =()=>{
        const selectedEmployeeData = employees.find((employee) => employee.id === selectedEmployee);
      
        setEmployeeEmail(selectedEmployeeData.email)
        setEmployeeid(selectedEmployeeData.id)
        setEmployeepassword(selectedEmployeeData.password)
        setEmployeename(selectedEmployeeData.name)
        setEmployeeImage(selectedEmployee.photo)

      }

      useEffect(() => {
        if (selectedEmployee) {
          setEmployeeDetails();       
        }
      
      }, [selectedEmployee])



      const husdata = {
        "name": Employeename,
        "Organization ID": oid,
        "Email": EmployeeEmail,
        "id": Employeeid,
        "role": "sub_admin",
        "Password": Employeepassword,
        'locId':[mylocation],
        'Locations':[],
        'Photo':EmployeeImage
    }

      const postManagerseData= async ({id, managedata})=>{
        setLoading(true);
  
        const status = await  AddManagerAsSubAdmin({ OrganizationID: id, data: managedata });
        if(status === true){
          setLoading(false);
          showSuccess();
        }else if (status === false) {
          setLoading(false);
          showFailure();
        }
    
       }

      const load = (e)  => {
        e.preventDefault();
        postManagerseData({id: oid, managedata: husdata});
        // console.log(husdata)
        setLoading(false);
        
        

         
        // setTimeout(() => {
        //   setLoading(false);
        //   showSuccess();
        //   console.log(data)
        // }, 2000);
      };
  return (
    <div>
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'   style={{ zIndex: 1000 }} >
            
        <Toast ref={toast} />
        <div className='bg-white rounded-lg shadow-lg sm:w-5/6 md:w-1/4 lg:w-1/5 relative lg:h-1/3 md:h-1/3 overflow-y-auto' >
        <button
          className="absolute top-2 right-2"
          onClick={onClose}
          style={{
            width: '24px',
            height: '24px',
          }}
        >
          <IoIosCloseCircle className="text-indigo-500" size={24} />
        </button>
        <div className='p-4'>
        <h2 className="text-2xl font-bold mb-4">Add Manager</h2>
        <form onSubmit={load}>
        <div className="mb-4" >
  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
    Employee:
  </label>
  <Dropdown
  filter={showFilterforEmployee}
  className='w-full'
  value={selectedEmployee}
  options={employees.map(loc => ({ label: loc.name, value: loc.id }))}
  onChange={handleEmployeeChange}
  placeholder= 'Select any of your Employee'
  required
  style={{ zIndex: 6000 }}

 
  />
</div>
        
            <div className="mb-4" >
  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
    Location:
  </label>
  <Dropdown
  filter = {showFilterforLocations}
  className='w-full'
  value={mylocation}
  options={locations.map(loc => ({ label: loc.Location_name, value: loc.Location_ID }))}
  onChange={handleLocationChange}
  placeholder= 'Assign Company Location'
  required
  style={{ zIndex: 6000 }}

 
  />
</div>
<div className="card flex flex-wrap justify-end justify-content-center gap-3 sm:mt-11 md:mt-11 lg:mt-11 ">
              <Button type="submit" label="Add as Manager" icon="pi pi-check" loading={loading} />
            </div>
            
        </form>

        </div>


        </div>

        </div>
      
    </div>
  )
}

export default AddManagerPopUp
