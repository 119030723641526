import React from 'react'

function Chart({tittle}) {
  return (
    <div>
      {tittle}
    </div>
  )
}

export default Chart
