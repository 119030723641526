import React, {useState, useEffect} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Chart } from 'primereact/chart';
import EmployeeSVG from '../Data/IMG/payroll.png'

function Payroll({pay, status}) {
  // const [chartData, setChartData] = useState({});
  //   const [chartOptions, setChartOptions] = useState({});
  //   const [DepartmentName, SetDepartmentName] = useState([]);
  //   const [DepartmentPayroll, SetDepartmentPayroll] = useState([]);


  //   const departmentNames = pay.departmentPayrollList.map((department) => department.department);
  //     // const departmentpayroll = pay.departmentPayrollList.map((department) => department.amount.replace(/,/g, ''));
  //     const departmentPayroll = pay.departmentPayrollList.map((department) => {
  //       const cleanedAmount = department.amount.replace(/,/g, ''); 
  //       return cleanedAmount;
  //     });
    
   

  //   useEffect(() => {
      
      
  //     // console.log(departmentPayroll);

  //   SetDepartmentName(departmentNames);
  //   SetDepartmentPayroll(departmentPayroll);
      
      
  //     const data = {
  //         labels: DepartmentName,
  //         datasets: [
  //             {
  //                 label: 'Payroll',
  //                 data: DepartmentPayroll,
  //                 backgroundColor: [
  //                     'rgba(255, 159, 64, 0.2)',
  //                     'rgba(75, 192, 192, 0.2)',
  //                     'rgba(54, 162, 235, 0.2)',
  //                     'rgba(153, 102, 255, 0.2)'
  //                   ],
  //                   borderColor: [
  //                     'rgb(255, 159, 64)',
  //                     'rgb(75, 192, 192)',
  //                     'rgb(54, 162, 235)',
  //                     'rgb(153, 102, 255)'
  //                   ],
  //                   borderWidth: 1
  //             }
  //         ]
  //     };
  //     const options = {
  //         scales: {
  //             y: {
  //                 beginAtZero: true
  //             }
  //         }
  //     };

  //     setChartData(data);
  //     setChartOptions(options);
  // }, []);

  // console.log(pay)

  const amountBodyTemplate = (rowData) =>{
    return (
      <span className="text-indigo-500">{rowData.amount}</span>
    );
  }
  return (
    <div className='text-black'>
      <div className='text-black font-bold mb-2'>Payroll</div>
      
      {status === false  ? (<div>
        <div>
      <div className="bg-white p-4 shadow rounded-lg mt-5">
  <div className="">
    <div className=" w-full">
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <div className="flex max-[991px]:flex max-[991px]:flex-col max-[991px]:gap-y-16  gap-20 justify-between">
                <div className="w-full p-4 max-[991px]:max-w-[720px] sm:w-full sm:p-6 md:p-10 lg:w-[600px]">
                  <div className="mb-4">
                    <h2 className="text-3xl font-semibold md:text-5xl">Efficient payroll, happy payroll. Keep your team smiling</h2>
                  </div>
                  <div className="mb-12">
                    <div className="flex flex-row items-center gap-x-4 max-[479px]:flex-row max-[479px]:justify-start max-[479px]:gap-y-[0px] sm:items-center">
                      <div className="text-lg text-[#414141]">Your set payroll runs appear here.</div>
                    </div>
                  </div>
                  {/* <a href="https://focus-template-bf49ac.webflow.io/blog-posts/the-productivity-mindset-cultivating-habits-for-success" className="inline-block cursor-pointer items-center self-center rounded-lg bg-indigo-900 px-6 text-center font-['DM_Sans',_sans-serif] text-lg font-bold text-white no-underline transition hover:bg-indigo-600 hover:outline-0 py-3">Onboard Employees</a> */}
                </div>
                <div className="h-[416px] w-full max-[991px]:items-center max-[991px]:justify-center sm:w-full lg:w-[618px]">
                  <img src={EmployeeSVG} alt="Employee Image" className="mx-auto inline-block h-full w-full max-w-none object-cover lg:max-w-[640px] rounded-2xl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </div>

      </div>):(
      <div>
      <div className='flex space-x-4'>
      <div className='w-screen min-w-[150px]'>
        <div className='flex'>
        <div className='bg-white  p-4 shadow rounded-lg flex-1 mr-4'>
          <div className='mb-8 text-indigo-500'>
            {pay.totalAnnualPayroll}
          </div>
          <div className='text-xl font-bold'>
          🗓 Annual Payroll</div>
        </div> 
        <div className='bg-white  p-4 shadow rounded-lg flex-1 mr-4'>
        <div className='mb-8 text-indigo-500'>
            {pay.totalMonthlyPayroll}
          </div>
          <div className='text-xl font-bold'>
          📅 Monthly Payroll
          </div>
          
        </div>
        <div className='bg-white  p-4 shadow rounded-lg flex-1 mr-4'>
        <div className='mb-3'>
            {pay.highestPayrollDepartment}
            <div className=' text-indigo-500'>
            {pay.highestPayrollAmount}</div>
          </div>
          <div className='text-xl font-bold'>
          ⬆️ Highest Payroll Department</div>
        </div>
        <div className='bg-white  p-4 shadow rounded-lg flex-1 mr-4'>
        <div className='mb-3'>
            {pay.lowestPayrollDepartment}
            <div className=' text-indigo-500'>
            {pay.lowestPayrollAmount}</div>
          </div>
          <div className='text-xl font-bold'>
         ⬇️ Lowest Payroll Department</div>
        </div>
        </div>

        {/* <div className="  bg-white p-5 shadow rounded-lg mt-10" >
            <Chart type="bar" data={chartData} options={chartOptions} style={{ width: '100vw', height: '35vh' }}
    />
        </div> */}

        <div className='bg-white p-4 shadow rounded-lg m-0 mt-5 mr-3'>
          <div className='font-bold text-black'>Department Wise Payroll</div>
          
          <div className='card m-2 mt-5'>
          <DataTable value={pay.departmentPayrollList} showGridlines stripedRows removableSort size='small' tableStyle={{ minWidth: '50rem' }}>
          <Column field="department" header="Department"  style={{ width: '25%' }}></Column>
          <Column body={amountBodyTemplate} header="Payroll" sortable style={{ width: '25%' }}></Column>
             </DataTable>

          </div>

        </div>
        
      </div>
      </div>

    </div>)}
      
    </div>
  )
}

export default Payroll
