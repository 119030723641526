class EmployeeModel {
    constructor(
      Phone,
      JoiningDate,
      Address,
      OrganizationID,
      Email,
      Gender,
      Department,
      Salary,
      EmployeeID,
      Photo,
      EmploymentStatus,
      Name
    ) {
      this.Phone = Phone;
      this.JoiningDate = JoiningDate;
      this.Address = Address;
      this.OrganizationID = OrganizationID;
      this.Email = Email;
      this.Gender = Gender;
      this.Department = Department;
      this.Salary = Salary;
      this.EmployeeID = EmployeeID;
      this.Photo = Photo;
      this.EmploymentStatus = EmploymentStatus;
      this.Name = Name;
    }
  }
  
  export function parseEmployeeData(jsonData) {
    if (Array.isArray(jsonData.body)) {
      // Check if the 'body' property is an array
      const employees = jsonData.body.map((employeeData) => {
        return new EmployeeModel(
          employeeData.Phone,
          employeeData.JoiningDate,
          employeeData.Address,
          employeeData.OrganizationID,
          employeeData.Email,
          employeeData.Gender,
          employeeData.Department,
          employeeData.Salary,
          employeeData["Employee ID"],
          employeeData.Photo,
          employeeData.EmploymentStatus,
          employeeData.Name
        );
      });
  
      return employees;
    } else {
      // Handle the case where 'body' is not an array
      return [];
    }
  }
  
  