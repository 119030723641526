import React, { useState, useEffect } from 'react';
import { IoIosCloseCircle } from "react-icons/io";
import MarkersMap from './Map/currentLocation';
import { Button } from 'bootstrap';

function EditPopup({ onClose, organization, checkNewUserOrNot, setDone, ip, isNotAstartup }) {
  // console.log(organization)
  const [Latitude, SetLatitude] = useState('');
  const [Longitude, SetLongitude] = useState('');
  const [loadingLocation, setLoadingLocation] = useState(true);
  const loadingElement = <div>Loading your office Location here...</div>;
  const apiKey = 'AIzaSyCc-N62kQBjNPFBL0DGgi87RWhAdm0zacE';
  


  useEffect(() => {
    // Check if geolocation is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        SetLatitude(position.coords.latitude);
        SetLongitude(position.coords.longitude);
        setLoadingLocation(false);
      });
    } else {
      console.log("Geolocation is not available in this browser.");
    }
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 " style={{ zIndex: 1000 }}>
      <div className="bg-blue-50 rounded-lg shadow-lg p-2">
        <div>
          
          
   
          <button className="absolute top-5 right-5" onClick={onClose}>
            <IoIosCloseCircle className='text-white' size={30} />
          </button>
         
          <div className=''> {
            loadingLocation ? (<p className='flex items-center justify-center'>Loading your office Location...</p>): (<div>
              <MarkersMap
             
              lat={Latitude} lng={Longitude} organization={organization} onClose={onClose} checkNewUserOrNot={checkNewUserOrNot} setDone={setDone} ip={ip} isNotAstartup={isNotAstartup}/>
                
            </div>
              
              
            )
            }
            
          </div>
          </div>
      </div>
    </div>
  );
}

export default EditPopup;
