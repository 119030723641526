export default function processEmployeeData(employeeData) {
  // Check if employeeData is an array
  if (!Array.isArray(employeeData)) {
    console.error('Employee data is not an array:', employeeData);
    return null; // Or handle the error in a way that makes sense for your application
  }

  // Initialize an object to store department counts
  const departmentCounts = {};

  // Iterate through the employee data
  employeeData.forEach((employee) => {
    const department = employee.Department;

    // If the department is already in the counts object, increment the count
    if (departmentCounts[department]) {
      departmentCounts[department] += 1;
    } else {
      // Otherwise, initialize the count to 1
      departmentCounts[department] = 1;
    }
  });

  // Convert the departmentCounts object into an array of objects
  const departmentArray = Object.keys(departmentCounts).map((department) => ({
    name: department,
    strength: departmentCounts[department],
  }));

  // Now, departmentArray contains objects with department names and their strengths
  return {
    departments: departmentArray,
  };
}
