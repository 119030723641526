import React, { useEffect, useState } from 'react';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import Slide from 'react-reveal/Slide';
import { useNavigate } from 'react-router-dom';
import { clearPersistedData } from '../../redux/action';
import EditPopup from '../setting/officePopUp';
import OfficeTiming from '../setting/organizationSetting/officeTiming';

function NewUser({ organization, checkNewUserOrNot, isNotAStartup}) {
  console.log(isNotAStartup) 
  // it should be true if not a startup



  const [isPopupOpen, setPopupOpen] = useState(false);
  const [secondStep, setSecondStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const [ip, setIp] = useState('')
  const [timer, setTimer] = useState(15);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showIP, setShowIP] = useState(false);


  const getData = async() =>{
    const res = await fetch('https://geolocation-db.com/json/', {
      method: 'GET',
    });
    const mydata = await res.json();
    if (res.ok) {
      setIp(mydata.IPv4);
    } else {
      setIp('something went wrong while getting your IP address. Please try again leter')
    }
  }



    const openPopup = () => {
      setPopupOpen(true);
    };
  
    const closePopup = () => {
      setPopupOpen(false);
    };

    const handleSteps = (e) => {
      e.preventDefault();
      
        setSecondStep(true);
      
    };
    const handleTimeOver = () => {
      localStorage.removeItem('token');
      dispatch(clearPersistedData());
      window.location.reload();
      navigate("/login");

    };

    useEffect(() => {
      if (isNotAStartup) {
        getData();
      }
    
    }, []);
    useEffect(() => {
      let countdown;
      if (thirdStep && timer > 0) {
        countdown = setInterval(() => {
          setTimer((prevTimer) => {
            if (prevTimer === 1) {
              // Timer reached 0, call handleTimeOver
              handleTimeOver();
            }
            return prevTimer - 1;
          });
        }, 1000);
      }
  
      return () => {
        clearInterval(countdown);
      };
    }, [thirdStep, timer]);



  return (
    <div>
      {thirdStep && (
        <div className='flex flex-col justify-center items-center h-screen w-screen '>
          {/* Add your third step content here */}
          <h1 className='text-5xl font-bold md:text-5xl sm:text-2xl text-black'>Hooray! 🎉</h1>
          <h2 className='text-3xl font-bold md:text-3xl sm:text-xl mt-5'>You've successfully cracked the setup of success</h2>
          <p className='mt-1'>It's time to log out, log back in, and witness the positive changes in your HR system</p>
          <p className='text-gray-500 mt-3'>Logging you out in: {timer}s</p>
          <button className='text-white bg-indigo-900 pl-20 pr-20 pt-5 pb-5 text-lg rounded-lg shadow-lg cursor-pointer hover:bg-indigo-700 mt-10' onClick={handleTimeOver}>Logout</button>
        </div>
      )}
      {
        secondStep && !thirdStep && (<div className='flex flex-col justify-center items-center h-screen w-screen'>
          <div className='flex flex-col w-1/3 justify-center items-center'>
          <h1 className='ext-5xl font-bold md:text-4xl sm:text-2xl text-black text-center'>WELCOME TO TOKEN TALENT 😍</h1>
      <p className='text-center mt-5 mb-14 text-gray-500'>We're excited to have you on board. To get started, please set up Token Talent by providing some of your simple information.</p>
      {/* <div className='bg-indigo-500 hover:bg-indigo-700 text-white rounded-lg pt-5 pb-5 pl-7 pr-7 mt-2 cursor-pointer shadow-lg' onClick={openPopup}>Mark location +</div> */}
      
      
      <div className='bg-blue-100 p-10 rounded-lg shadow-lg flex flex-col gap-3'>
      <div className='mb-5'>
      <ul>
        <li>1. Define your company's operating days.</li> 
        <li>2. Establish your company's working hours.</li> 
        {
          isNotAStartup && <li>3. Bind your IP address to allow employees to mark attendance only when connected to the office network (as you are connected right now). This ensures that employees are at their desks during check-in and check-out, providing HR with accurate and reliable attendance data. Confirm your IP address by <a href="https://whatismyipaddress.com/" target="_blank" class="hover:underline" className="text-blue-500"> <span class="underline">click here</span></a></li>
        }
        
        <li>4. Mark your company's location for attendance tracking.</li>
      

      </ul>
      </div>
        <OfficeTiming decriptedOrganization={organization}></OfficeTiming>
        <div className='flex flex-col gap-5'>
          {
            isNotAStartup && <div className='bg-white p-4 shadow rounded-lg text-black flex flex-col gap-3'>
            <label className='flex item-center gap-2'>
            <input className='text-indigo-900'
            type='checkbox'
            checked={showIP}
            onChange={() => setShowIP(!showIP)}
          />
          Bind IP Address
          
        </label>
            {showIP && <div className='bg-gray-300 p-4 shadow rounded-lg text-black'>
              {ip}
       
            </div>}
            
            </div>
          }
          
        <div className='bg-indigo-500 hover:bg-indigo-700 text-white rounded-lg pt-5 pb-5 pl-7 pr-7 mt-2 cursor-pointer shadow-lg text-center' onClick={openPopup}>+ Pin Company Location</div>
        </div>
        
      </div>
          </div>
        </div>)

      }
      {
        !secondStep && !thirdStep &&  (
          <div className='flex flex-col justify-center items-center h-screen space-y-4'>
            <Slide top>

            <h1 className='text-5xl font-bold md:text-5xl sm:text-2xl text-black'>WELCOME TO TOKEN TALENT 😍</h1>
            </Slide>
            <Slide bottom>
            <p className='mt-10 text-gray-500'>We are happy to see you here</p>
            </Slide>
            <div className='flex justify-center items-center mt-6'>
        <Slide bottom>
          <button
          type='submit'
            className='bg-indigo-500 hover:bg-indigo-800 rounded-full h-10 w-10 flex items-center justify-center'
            onClick={handleSteps}
          >
            <HiOutlineArrowNarrowRight className='text-white' />
          </button>
          </Slide>
        </div>
          </div>

        )
      }
      
      
      {isPopupOpen && <EditPopup onClose={closePopup} organization={organization} checkNewUserOrNot={checkNewUserOrNot} setDone={setThirdStep} ip={ip} isNotAstartup={isNotAStartup}/>}
</div>

  )
}

export default NewUser;
