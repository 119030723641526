import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import React, { useState } from 'react';
import { CheckImageOrLabelForEmployeeTable } from '../../Provider/checkImage';
import { useLocation } from 'react-router-dom';

function LeavesContent(leave) {

    // console.log(leave.leave)
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('')
    const [status, setStatus] = useState('');
    const location = useLocation();
  const isHomePage = location.pathname === '/';
    

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            representative: { value: null, matchMode: FilterMatchMode.IN },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
            verified: { value: null, matchMode: FilterMatchMode.EQUALS }
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                <span className="p-input-icon-left mr-2 ml-2">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };


    

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.leaveRequestStatus} severity={getSeverity(rowData.leaveRequestStatus)} />;
    };

    const statusFilterTemplate = (options, rowData) => {
        
        return <Dropdown value={options.value} options={statuses} onChange={(e) => 
            options.filterCallback(e.value, options.index)
        } itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

   
    const header = renderHeader();
    const [statuses] = useState(["Approved", 'Rejected',  "Pending"]);

    const getSeverity = (status) => {
        switch (status) {
            case 'Rejected':
                return 'danger';

            case 'Approved':
                return 'success'; 

            case 'Pending':
                return 'warning';
        }
    };
    const statusEditor = (options, rowData) => {
        console.log(rowData)
        return (
            <Dropdown
                value={options.value}
                options={statuses}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <Tag value={option} severity={getSeverity(option)}></Tag>;
                }}
            />
        );
            };

            

    const employeeBodyTemplate = (rowData) =>{
        const name = CheckImageOrLabelForEmployeeTable(rowData)
        return (
            <div className='flex items-center'>
                {rowData.Photo === '' ? 
                <Avatar 
                label={name}
                style={{ backgroundColor: '#32317f', color: '#ffffff' }}
                className="mr-2" shape="circle" /> : 
                <Avatar 
                image={rowData.Photo}
                className="mr-2" size="large" shape="circle" />}
                <div className="flex flex-col">
                    <span className='text-black'>{rowData.Name}</span>
                    <span className="text-indigo-500 text-xs">{rowData.Email}</span>
                    {/* <span className="text-gray-500 text-xs">{rowData.phone}</span> */}
                </div>
            </div>
        );

    }
    

  return (
    <div className='bg-white p-4 shadow rounded-lg m-0'>
        <div className="card">
            <DataTable value={leave.leave} removableSort resizableColumns paginator={isHomePage} showGridlines rows={isHomePage ? 5 : undefined} size={'small'} loading={loading} dataKey="Leave Request ID" 
                    filters={filters} globalFilterFields={['Name', 'Employee ID', 'leaveRequestStatus', 'Reason', 'Duration']} header={header}
                    emptyMessage="No Leave Request Today 😳">
                        <Column field="Employee ID" header="Employee ID" filter sortable filterPlaceholder="Search by id" style={{ minWidth: '5rem' }} />
                        <Column header="EMPLOYEE" filterField="name" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }}
                    body={employeeBodyTemplate} filter  />
                     <Column field="Department" header="Department" filterField="department" style={{ minWidth: '10rem' }} filter />
                     <Column field="LeaveType" header="Type" filterField="department" style={{ minWidth: '10rem' }} filter  />
                     <Column field="Duration" header="Duration" filterField="department" style={{ minWidth: '10rem' }} filter  />
                     <Column field="StartDate" header="From" filterField="department" style={{ minWidth: '10rem' }} filter  />
                     <Column field="EndDate" header="To" filterField="department" style={{ minWidth: '10rem' }} filter />
                     
                     <Column field="leaveRequestStatus" header="Status" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '6rem' }} editor={(options, rowData) => statusEditor(options, rowData)}  body={statusBodyTemplate} filter filterElement={statusFilterTemplate} />
            </DataTable>
        </div>
      
    </div>
  )
}

export default LeavesContent
