import { AddManagerUrl } from "../model/url";


export async function AddManagerAsSubAdmin({OrganizationID, data}) {
    const url = AddManagerUrl(OrganizationID);
    // console.log(JSON.stringify(data))
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      });
    //   console.log(response)
      if (response.ok) {
        const resdata = await response.json();
    
        const status = resdata["statusCode"];
        if (status === 200) {
            // console.log(resdata.body)
            return true;
        } else if (status !==200) {
            return false; 
        }
      }else{
        return false;
      }

     

    
  }