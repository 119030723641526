import React, { useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { IoIosCloseCircle } from 'react-icons/io';
import Papa from 'papaparse';

function ImportEmployee({ onClose }) {
  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const parseCSVToJson = (csvData) => {
    const results = Papa.parse(csvData, {
      header: true, // Assumes the first row is the header
    });

    if (results.data.length > 0) {
      console.log('CSV to JSON:', results.data);
    }
  };

  const readFileAsJSON = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const csvData = event.target.result;
        const results = Papa.parse(csvData, {
          header: true, // Assumes the first row is the header
        });

        if (results.data.length > 0) {
          resolve(results.data);
        } else {
          reject("Error parsing CSV data");
        }
      };

      reader.readAsText(file);
    });
  };

  const onTemplateSelect = async (e) => {
    let _totalSize = totalSize;
    let files = e.files;

    Object.keys(files).forEach(async (key) => {
      _totalSize += files[key].size || 0;

      const file = files[key];
      try {
        const jsonData = await readFileAsJSON(file);
        if (jsonData) {
          console.log('CSV to JSON:', jsonData);
        }
      } catch (error) {
        console.error(error);
      }
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 5 MB</span>
          <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap ">
        <div className="flex align-items-center bg-indigo-500 p-2 mr-7 rounded-md text-white" style={{ width: '40%' }}>
          <div className="pi pi-file-excel mt-3 p-5" style={{ fontSize: '1.5em', borderRadius: '50%', color: 'var(--surface-d)' }}></div>
          <span className="flex flex-column text-left ml-3 text-xs">
            {file.name}
          </span>
        </div>
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-file-excel mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
          Drag and Drop CSV File Here
        </span>
      </div>
    );
  };

  const chooseOptions = { icon: 'pi pi-fw pi-file-excel', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
      <Toast ref={toast}></Toast>
      <div className='bg-white rounded-lg shadow-lg w-2/6 relative '>
        <button
          className="absolute top-2 right-2"
          onClick={onClose}
          style={{
            width: '24px',
            height: '24px',
          }}
        >
          <IoIosCloseCircle className="text-indigo-500" size={24} />
        </button>
        <div className='p-9'>
          <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
          <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
          <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

          <FileUpload
            ref={fileUploadRef}
            name="employee"
            url="/api/upload"
            multiple
            accept=".csv" // Only allow CSV files
            maxFileSize={5000000} // 5MB maximum file size
            onUpload={onTemplateUpload}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
          />
          <div className="text-right mt-0 mb-0 ">
            <button className="p-button p-button-text p-4 text-indigo-700">Download Template</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportEmployee;
