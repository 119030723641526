export function getFullDayName(dateString) {
    const [day, month, year] = dateString.split('/').map(Number);

    // Create a Date object using the correct format
    const dateObject = new Date(year, month - 1, day); // Month is zero-based

    // Get the full day name
    const dayName = dateObject.toLocaleDateString('en-US', { weekday: 'long' });


    return dayName;
}