import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import { ToggleButton } from 'primereact/togglebutton';
import React, { useEffect, useRef, useState } from 'react';
import { UpdateTimings, UpdateWorkingHours } from '../../../Provider/AddWorkingDaysAndTime';

function OfficeTiming({decriptedOrganization}) {
    const [Monday, setChecked] = useState(false);
    const [Tuesday, setCheckedt] = useState(false);
    const [Wednesday, setCheckedw] = useState(false);
    const [Thursday, setCheckedth] = useState(false);
    const [Friday, setCheckedf] = useState(false);
    const [Saturday, setCheckeds] = useState(false);
    const [Sunday, setCheckedsu] = useState(false);
    const [timestart, setTimestart] = useState(null);
    const [timeend, setTimestartend] = useState(null);
    const [loading, setLoading] = useState(false);
    const [workingDays, setworkingDays] = useState([]);
    const [firstVisible, SetfirstVisible] = useState(false);
    const [secondVisible, SetsecondVisible] = useState(false);
    const toast = useRef(null);
    const id = decriptedOrganization["Organization ID"];
    const mystatus = decriptedOrganization["Status"];

    useEffect(() => {
        if (decriptedOrganization.Timing !== null) {
            setTimestart(decriptedOrganization.Timing.startTime)
            setTimestartend(decriptedOrganization.Timing.EndTime)
            
        } else {
            
        }
    
    }, [decriptedOrganization.Timing])




    const formattedStartTime = timestart ? timestart.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
    }) : '';
    const formattedEndTime = timeend ? timeend.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short',
    }) : '';

    const data = {
        "WorkingDays":workingDays
    }
    const timing = {
        "Timing": [
            {
                "startTime": formattedStartTime,
                "EndTime": formattedEndTime
            }
        ]
    }

    const showSuccess = () => {
        toast.current.show({severity:'success', summary: 'Success', detail:'Settings has been updated', life: 3000});
    }
    const showError = () => {
        toast.current.show({severity:'error', summary: 'Error', detail:'Settings could not be updated. Something went wrong', life: 3000});
    }


    const loadforFirst = async () => {
        setLoading(true);
        // console.log(mystatus)
        const status = await UpdateWorkingHours(id, mystatus, data);
     
        if (status === true) {
            setLoading(false);
            SetfirstVisible(false);
            showSuccess();
        }else{
            setLoading(false);
            showError();


        }
        
    };
    const loadforSecond = async () => {
        setLoading(true);
        const status = await UpdateTimings(id, mystatus, timing);
       
        if (status === true) {
            setLoading(false);
            SetsecondVisible(false);
            showSuccess();
        }else{
            setLoading(false);
            showError();


        }
       
    };

    useEffect(() => {
        const selectedDay = [
            { day: "Monday", checked: Monday },
            { day: "Tuesday", checked: Tuesday },
            { day: "Wednesday", checked: Wednesday },
            { day: "Thursday", checked: Thursday },
            { day: "Friday", checked: Friday },
            { day: "Saturday", checked: Saturday },
            { day: "Sunday", checked: Sunday },
        ];

        const selectedDayNames = selectedDay.filter(day => day.checked).map(day => day.day);
        setworkingDays(selectedDayNames);

        // const anyDaysSelected = selectedDayNames.length > 0;
        // SetfirstVisible(anyDaySelected);

        const selectedDays = [Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday];
        const anyDaySelected = selectedDays.some((day) => !decriptedOrganization.WorkingDays.includes(day));
        const hasChanges = JSON.stringify(selectedDayNames) !== JSON.stringify(decriptedOrganization.WorkingDays);
        SetfirstVisible(hasChanges);
    }, [Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday, decriptedOrganization.WorkingDays]);

    useEffect(() => {
        if (timestart !== decriptedOrganization.Timing.startTime || timeend !== decriptedOrganization.Timing.EndTime) {
            SetsecondVisible(true);
            // Show the second update button
        } else {
            SetsecondVisible(false);
            // Hide the second update button
        }
    }, [timestart, timeend]);

    useEffect(() => {
        const workingDaysFromOrg = decriptedOrganization.WorkingDays;
    
        // Create an array with the initial state of the days based on the organization data
        const daysMap = {
            Monday: setChecked,
            Tuesday: setCheckedt,
            Wednesday: setCheckedw,
            Thursday: setCheckedth,
            Friday: setCheckedf,
            Saturday: setCheckeds,
            Sunday: setCheckedsu
        };
    
        for (const day in daysMap) {
            daysMap[day](workingDaysFromOrg.includes(day));
        }
    
        // Rest of your code...
    }, []);


  return (
    <div className='space-y-2 mb-2'>
        <Toast ref={toast} />
        <div className='bg-white w-90% p-4 shadow rounded-lg text-black'>
        Working Days
        <div className='p-5 flex '>
            <div className='sm:flex-col space-x-2 space-y-2'>
            <ToggleButton onLabel="Monday" offLabel="Monday" checked={Monday} onChange={(e) => setChecked(e.value)} className="w-8rem" />
        <ToggleButton onLabel="Tuesday" offLabel="Tuesday" checked={Tuesday} onChange={(e) => setCheckedt(e.value)} className="w-8rem" />
        <ToggleButton onLabel="Wednesday" offLabel="Wednesday" checked={Wednesday} onChange={(e) => setCheckedw(e.value)} className="w-8rem" />
        <ToggleButton onLabel="Thursday" offLabel="Thursday" checked={Thursday} onChange={(e) => setCheckedth(e.value)} className="w-8rem" />
        <ToggleButton onLabel="Friday" offLabel="Friday" checked={Friday} onChange={(e) => setCheckedf(e.value)} className="w-8rem" />
        <ToggleButton onLabel="Saturday" offLabel="Saturday" checked={Saturday} onChange={(e) => setCheckeds(e.value)} className="w-8rem" />
        <ToggleButton onLabel="Sunday" offLabel="Sunday" checked={Sunday} onChange={(e) => setCheckedsu(e.value)} className="w-8rem" />
            </div>

        </div>
        {
                firstVisible ? <Button label="Update" icon="pi pi-check" loading={loading} onClick={loadforFirst} /> : null
            }
        </div>
        <div className="bg-white w-90% p-4 shadow rounded-lg text-black">
            Organization Timing
            <div className='p-5 flex space-x-2'>
            <div className="flex-auto">
                <label htmlFor="Check IN Time" className="font-bold block">
                    Check In Time
                </label>
                <label htmlFor="Check IN Time" className="text-xs block mb-2">
                   (Day Start Time)
                </label>
                <Calendar id="calendar-timeonly" value={timestart} onChange={(e) => setTimestart(e.value)} timeOnly  hourFormat="12" />
            </div>
            <div className="flex-auto">
                <label htmlFor="Check OUT Time" className="font-bold block">
                    Check Out Time
                </label>
                <label htmlFor="Check OUT Time" className="text-xs block mb-2">
                   (Day End Time)
                </label>
                <Calendar id="calendar-timeonly" value={timeend} onChange={(e) => setTimestartend(e.value)} timeOnly  hourFormat="12"/>
            </div>
            {/* <Calendar id="calendar-timeonly" value={time} onChange={(e) => setTime(e.value)} timeOnly hourFormat="12"/> */}

            </div>
            {
                secondVisible ? <Button label="Update" icon="pi pi-check" loading={loading} onClick={loadforSecond} /> : null
            }
             

        </div>
      
    </div>
  )
}

export default OfficeTiming
