export function getUpcomingHolidays(holidaysData) {
    const today = new Date();
    const twoMonthsFromToday = new Date();
    twoMonthsFromToday.setMonth(twoMonthsFromToday.getMonth() + 2);
  
    const upcomingHolidays = holidaysData.filter((holiday) => {
      const holidayDate = new Date(holiday.date);
      return holidayDate > today && holidayDate <= twoMonthsFromToday;
    });
  
    return upcomingHolidays.map((holiday) => ({
      date: holiday.date,
      name: holiday.name,
    }));
  }


  export function extractHolidayDataForPakistan(holidaysData) {
    const holidays = holidaysData.response.holidays;
    const uniqueHolidayNames = new Set();
  
    return holidays.reduce((result, holiday) => {
      const holidayName = holiday.name;
  
      if (!uniqueHolidayNames.has(holidayName)) {
        uniqueHolidayNames.add(holidayName);
  
        result.push({
          date: holiday.date.datetime.day,
          name: holidayName,
          type: holiday.type,
        });
      }
  
      return result;
    }, []);
  }
  
  export async function fetchHolidayData(apiUrl) {
    try {
      const response = await fetch(apiUrl);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const myHolidays = extractHolidayDataForPakistan(data);
      return myHolidays;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  