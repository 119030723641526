import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';
import { Slider } from 'primereact/slider';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { classNames } from 'primereact/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CombineEmployeeAttendanceData, attendanceCombinedAndFiltered } from '../../Provider/CombineAttendanceData';
import { decryptMydata } from '../../Provider/EncriptionAndDecription';
import { CheckImageOrLabelForAttendance } from '../../Provider/checkImage';
import { EncryptionAndDecryptionKeys } from '../../model/url';
import { CustomerService } from '../service/CustomerService';
import { getEmployeesAttendance } from '../../Provider/CombineAttendanceData';
import { BsFillCalendarDateFill } from "react-icons/bs";


const AttendanceNewTable = ({attendance, length, employee}) => {
   
    // const key = EncryptionAndDecryptionKeys();
    // const EmployeeData = useSelector((state) => state.em.em);
    const [Attendance, setAttendance] = useState(null);
    const [Representatives, setRepresentavtives] = useState([]);
    // const decryptedEmployeeData = JSON.parse(decryptMydata(EmployeeData, key))


    const footerTemplate = (data) => {
        return ( 
            <React.Fragment>
                <td colSpan="8">
                    <div className="flex justify-end font-bold w-full pr-20"></div>
                </td>
            </React.Fragment>
        );
    };

    const headerTemplate = (data) => {
        return (
            <div className="flex item-center jusity-center h-full">
                {/* <BsFillCalendarDateFill  className=''/> */}
                <span className="font-bold text-xl text-indigo-500">{data.date}</span>
            </div>
        );
    };


    const mylocationTemplate = (rowData) => {  
        return <Tag value={rowData.location}/>;
        
    }
  

    //  console.log(Attendance)

    const GetDataAndCreateModels = async (e) => {
    // const combinedData = await CombineEmployeeAttendanceData(decryptedEmployeeData, attendance);
    const filteredData = getEmployeesAttendance(employee, attendance);

    //  console.log(filteredData)

    if (typeof filteredData === 'object' && !Array.isArray(filteredData)) {
        const formattedData = [];
    
        Object.keys(filteredData).forEach((date) => {
            filteredData[date].forEach((attendanceData) => {
                formattedData.push({
                    'Employee ID': attendanceData.employeeId,
                    name: attendanceData.name,
                    email: attendanceData.email,
                    phone: attendanceData.phone,
                    department: attendanceData.department,
                    image: attendanceData.image || "",
                    status: attendanceData.status,
                    checkinTime: attendanceData.checkinTime,
                    checkoutTime: attendanceData.checkoutTime || "",
                    totalWorkingHours: attendanceData.totalWorkingHours || "",
                    location: attendanceData.location || "",
                    date: date, // Include the date in the formatted data
                });
            });
        });
    
        setRepresentavtives(formattedData.map((e) => ({
            name: e.name,
            image: e.image,
        })));
        
        // formattedData.forEach((item, index) => {
        //     console.log(`formattedData ${index + 1}:`, item);
        // });
    
        setAttendance(formattedData);
    } else {
        console.error('Filtered data is not in the expected format:', filteredData);
        // Handle the error or provide a default value as needed
    }
    
    // setRepresentavtives(combinedData.map((e)=> ({
    // name: e.name,
    // image: e.image,
    // })));
    // setAttendance(combinedData);
    }
    useEffect(() => { 
        GetDataAndCreateModels(); 
      }, []);



 



    //dead code
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    
    // ]);

    const [statuses] = useState(['Present', 'Absent',  'Late']);

    const getSeverity = (status) => {
        switch (status) {
            case 'Absent':
                return 'danger';

            case 'Present':
                return 'success'; 

            case 'Late':
                return 'warning';
        }
    };

    useEffect(() => {
        CustomerService.getCustomersMedium().then((data) => {
            setCustomers(getCustomers(data));
            setLoading(false);
        });
        initFilters();
    }, []);

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.date = new Date(d.date);

            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            representative: { value: null, matchMode: FilterMatchMode.IN },
            date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
            balance: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
            verified: { value: null, matchMode: FilterMatchMode.EQUALS }
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                <span className="p-input-icon-left mr-2 ml-2">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span> 
            </div>
        );
    };

    const representativeFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={Representatives} itemTemplate={representativesItemTemplate} onChange={(e) => options.filterCallback(e.value)} optionLabel="name" placeholder="Any" className="p-column-filter" />;
    };

    const representativesItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                  <Avatar image={option.image} className="mr-2" shape="circle" />
                {/* <img alt={option.image} src={option.image} width="32" /> */}
                <span>{option.name}</span>
            </div> 
        );
    };


    const balanceFilterTemplate = (options) => {
        return <InputNumber value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} mode="currency" currency="USD" locale="en-US" />;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    

    const activityBodyTemplate = (rowData) => {
        return <ProgressBar value={rowData.activity} showValue={false} style={{ height: '6px' }}></ProgressBar>;
    };

    const activityFilterTemplate = (options) => {
        return (
            <React.Fragment>
                <Slider value={options.value} onChange={(e) => options.filterCallback(e.value)} range className="m-3"></Slider>
                <div className="flex align-items-center justify-content-between px-2">
                    <span>{options.value ? options.value[0] : 0}</span>
                    <span>{options.value ? options.value[1] : 100}</span>
                </div>
            </React.Fragment>
        );
    };

    const verifiedBodyTemplate = (rowData) => {
        return <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.verified, 'text-red-500 pi-times-circle': !rowData.verified })}></i>;
    };

    const verifiedFilterTemplate = (options) => {
        return (
            <div className="flex align-items-center gap-2">
                <label htmlFor="verified-filter" className="font-bold">
                    Verified
                </label>
                <TriStateCheckbox inputId="verified-filter" value={options.value} onChange={(e) => options.filterCallback(e.value)} />
            </div>
        );
    };

    const header = renderHeader();

    //Dead Code

    const employeeBodyTemplate = (rowData) =>{
        
        const name = CheckImageOrLabelForAttendance(rowData);
        // console.log(name)

        return (
            <div className='flex items-center'>
                {rowData.image === '' ? 
                <Avatar 
                label={name}
                style={{ backgroundColor: '#32317f', color: '#ffffff' }}
                className="mr-2"  shape="circle" /> : 
                <Avatar 
                image={rowData.image}
                className="mr-2" size="large" shape="circle" />
                
            }
                {/* <Avatar image="https://primefaces.org/cdn/primereact/images/avatar/asiyajavayant.png" className="mr-2" shape="circle" /> */}
                <div className="flex flex-col">
                    <span className='text-black'>{rowData.name}</span>
                    <span className="text-indigo-500 text-xs">{rowData.email}</span>
                    {/* <span className="text-gray-500 text-xs">{rowData.phone}</span> */}
                </div>
            </div>
        );

    }
    
    const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const statusEditor = (options) => {
        return (
            <Dropdown
                value={options.value}
                options={statuses}
                onChange={(e) => options.editorCallback(e.value)}
                placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <Tag value={option} severity={getSeverity(option)}></Tag>;
                }}
            />
        );
    };

    const onRowEditComplete = (e) => {
        let _products = [...Attendance];
        let { newData, index } = e;

        _products[index] = newData;

        setAttendance(_products);
    }; 

  return (
    <div className='bg-white p-4 shadow rounded-lg mt-5'>
        
    <div className="card mt-0">

    {/* add ==> rowspan to rowGroupMode to show date wise attendance in row */}

        <DataTable value={Attendance} editMode="cell"  rowGroupMode="subheader" groupRowsBy="date" sortMode="single" sortField="date" sortOrder={-1}  removableSort resizableColumns showGridlines scrollable scrollHeight="75vh"  size={'small'} onRowEditComplete={onRowEditComplete}  rows={length} loading={loading}  
    filters={filters} globalFilterFields={['name', 'Employee ID', 'department', 'email', 'phone']} header={header} rowGroupHeaderTemplate={headerTemplate} rowGroupFooterTemplate={footerTemplate}
    emptyMessage="No Attendance Data found Looks like its a Holiday 😝">

                    {/* <Column field="date"  header="Date"  style={{ minWidth: '5rem' }} /> */}
            <Column field="Employee ID" header="Employee ID"   filterPlaceholder="Search by name" style={{ minWidth: '2vh' }} />
            {/* <Column header="EMPLOYEE" filterField="name" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }}
                    body={employeeBodyTemplate} filter filterElement={representativeFilterTemplate} /> */}
                       <Column header="EMPLOYEE"  style={{ minWidth: '14rem' }}
                    body={employeeBodyTemplate}   />
            {/* <Column header="EMPLOYEE" filter filterPlaceholder="Search by Employee"  filterMenuStyle={{ width: '12rem' }}  style={{ minWidth: '12rem' }} body={employeeBodyTemplate} filterElement={representativeFilterTemplate}/> */}
{/*             
            <Column field="department" header="Department" filterField="department" style={{ minWidth: '10rem' }} filter filterElement={balanceFilterTemplate} /> */}
             <Column body={mylocationTemplate} header="Location"   />
            <Column field="checkinTime" header="Check IN" editor={(options) => textEditor(options)} filterField="department"   filterElement={balanceFilterTemplate} />
            <Column field="checkoutTime" header="Check OUT" editor={(options) => textEditor(options)} filterField="department"   filterElement={balanceFilterTemplate} />
            <Column field="totalWorkingHours" header="WORKING HOURS" editor={(options) => textEditor(options)} filterField="department"   filterElement={balanceFilterTemplate} />
           
            
              {/* <Column header="Time In" filterField="balance" dataType="numeric" style={{ minWidth: '10rem' }} body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
            <Column header="Time Out" filterField="balance" dataType="numeric" style={{ minWidth: '10rem' }} body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
            <Column header="Total Hours" filterField="balance" dataType="numeric" style={{ minWidth: '10rem' }} body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
            <Column header="Actions" filterField="balance" dataType="numeric" style={{ minWidth: '10rem' }} body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />
            <Column header="Loctions" filterField="balance" dataType="numeric" style={{ minWidth: '10rem' }} body={balanceBodyTemplate} filter filterElement={balanceFilterTemplate} />*/}
            <Column field="status" header="Status" filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '6rem' }} editor={(options) => statusEditor(options)}  body={statusBodyTemplate} filter filterElement={statusFilterTemplate} />
            {/* <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>  */}
        </DataTable>
    </div> 
  
</div>
  )
}

export default AttendanceNewTable
