// export function CombineEmployeeAttendanceData(employees, attendance) {
//   // console.log(employees)
//     const attendanceMap = new Map();
//     attendance.forEach((attendanceItem) => {
//       attendanceMap.set(attendanceItem.EmployeeID, attendanceItem);
//     });
  
//     // Combine employee and attendance data
//     const combinedData = employees.map((employee) => {
//       const attendanceData = attendanceMap.get(employee['Employee ID']) || {};
//       // console.log(attendanceData)
//       const status = attendanceData.checkin && attendanceData.checkin[0] ? attendanceData.checkin[0].Status : 'Absent';
//       const checkinTime = attendanceData.checkin && attendanceData.checkin[0] ? attendanceData.checkin[0].Time : 'N/A';
//     const checkoutTime = attendanceData.checkout && attendanceData.checkout[0] ? attendanceData.checkout[0].Time : 'N/A';

//       return {
//         'Employee ID': employee['Employee ID'],
//         date: attendanceData.date,
//         image: employee.Photo,
//         name: employee.Name,
//         email: employee.Email,
//         phone: employee.Phone,
//         department: employee.Department,
//         status: status,
//         'Time In':checkinTime,
//         'Time Out':checkoutTime,
//         'Total Hours':'8.15',
//         'Loctions': employee.location


//       };
//     });

//     // console.log(combinedData)
  
//     return combinedData;
//   }

export function getEmployeesAttendance(employeeData, attendanceData) {
  const dateWiseAttendance = {};

  attendanceData.forEach((attendance) => {
    attendance.checkin.forEach((checkinData) => {
      const employeeId = checkinData.EmployeeID;
      const employee = employeeData.find((emp) => emp["Employee ID"] === employeeId);

      if (employee) {
        const date = attendance.Date;
        const checkinTime = checkinData.Time;

        // Find checkout data for the current employee
        const checkoutData = attendance.checkout.find(checkout => checkout.EmployeeID === employeeId);
        const checkoutTime = checkoutData ? checkoutData.Time : "";

        const location = employee.location;
        const status = checkinData.Status;

        // Calculate total working hours if checkout time is available
        const totalWorkingHours = checkoutTime ? calculateWorkingHours(checkinTime, checkoutTime, date) : "";

        const attendanceRecord = {
          employeeId,
          date: date,
          name: employee.Name,
          email: employee.Email,
          phone: employee.Phone,
          department: employee.Department,
          image: employee.Photo || "",
          status: status,
          checkinTime,
          checkoutTime,
          totalWorkingHours,
          location,
        };

        if (!dateWiseAttendance[date]) {
          dateWiseAttendance[date] = [];
        }

        dateWiseAttendance[date].push(attendanceRecord);
      }
    });
  });



  return dateWiseAttendance;
}


function calculateWorkingHours(checkinTime, checkoutTime, date) {
  // Implement logic to calculate working hours based on checkinTime and checkoutTime
  // For example, you can use JavaScript Date objects for better accuracy
  const checkin = new Date(`${date} ${checkinTime}`);
  const checkout = new Date(`${date} ${checkoutTime}`);
  const millisecondsDiff = checkout - checkin;
  const hours = millisecondsDiff / (1000 * 60 * 60);
  return hours.toFixed(2);
}
  
  
  

  