import { Avatar } from 'primereact/avatar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React from 'react';
import { calculateBirthday } from '../Provider/BirthdayCalculator';
import { FcBusinessContact } from "react-icons/fc";
import { CheckImageOrLabel } from '../Provider/checkImage';

function Birthday({employee}) {
    const birthdays = calculateBirthday(employee);
    const employeeBodyTemplate = (rowData) =>{

    
      const label = CheckImageOrLabel(rowData);
        return (
            
            <div className='flex items-center'>
              {rowData.Photo === '' ? 
              <Avatar 
                label={label}
                style={{ backgroundColor: '#32317f', color: '#ffffff' }}
                className="mr-2" size="large" shape="circle" /> : 
                <Avatar 
                image={rowData.Photo}
                className="mr-2" size="large" shape="circle" />}
                <div className="flex flex-col">
                    <span className='text-black'>{rowData.Name}</span>
                    <span className="text-indigo-500 text-xs">
                        {rowData.DaysRemaining === 0 ? 'Today' : `in ${rowData.DaysRemaining} Days`}
                    </span>

                </div>
            
            </div>
        );

    }
  return (
    <div>
        {birthdays.length === 0 ? (
        <div className="flex flex-col items-center justify-center p-6 text-center">
        <div className="bg-indigo-200 rounded-full w-20 h-20 flex items-center justify-center">
          <FcBusinessContact className="text-indigo-700 text-6xl" />
        </div>
        <p className="text-base sm:text-xs md:text-sm lg:text-xl font-bold mt-4 mb-2">No upcoming birthdays</p>
        <p className="text-base sm:text-xs md:text-sm lg:text-base">
        But, Come on, Why limit happiness to just one day?  Life is too short to skip dessert every day. 🍰😄
        </p>
        {/* Add any other message or quote here */}
      </div>
      
      ) : (
        <div>
            {birthdays.length>3 ? (<div>

        <DataTable value={birthdays}  rows={5} paginator  size='md' tableStyle={{ minWidth: '50rem' }}>
          <Column body={employeeBodyTemplate} header="Upcoming Birthdays"  style={{ width: '25%' }}></Column>
             </DataTable>
            </div>
            ) :(
            <div>
              <DataTable value={birthdays}  rows={5}  size='md' tableStyle={{ minWidth: '50rem' }}>
          <Column body={employeeBodyTemplate} header="Upcoming Birthdays"  style={{ width: '25%' }}></Column>
             </DataTable>

            </div>
            )}
        </div>
      
             )}
      
    </div>
  )
}

export default Birthday
