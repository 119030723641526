import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import Location from './location';
import OfficeTiming from './organizationSetting/officeTiming';
import LeavePolicy from './organizationSetting/LeavePolicy';
import OrganizationPolicy from './organizationSetting/OrganizationPolicy';
import { EncryptionAndDecryptionKeys } from '../../model/url';
import { decryptMydata } from '../../Provider/EncriptionAndDecription';
import SubAdmin from './organizationSetting/subAdmin';

function OrganizationSetting({isStartup, isPlatinum}) {
  const organization = useSelector((state) => state.or.or);
  const key = EncryptionAndDecryptionKeys();
  const decriptedOrganization = JSON.parse(decryptMydata(organization, key));

  const employees = useSelector((state) => state.em.em);
  const decriptedemployees = JSON.parse(decryptMydata(employees, key));

  const startupLocation = decriptedOrganization.Locations;
  const role = decriptedOrganization.role ?? '';
  const isSubAdmin = role === 'sub_admin' 
  // console.log(isSubAdmin)

  const [show_payroll, Set_show_payroll] = useState(null);
  const [show_leave, Set_show_leave] = useState(null);
  const [managers, Setmanagers] = useState(null);
  const [employeesForManagerPost, SetemployeesForManagerPost] = useState(null);

  
  const findLocationById = (id, data) => {
    const location = data.find(item => item.LocationID === id);
    if (location) {
      const { Location } = location;
      return Location;
    }
    return 'Location Not Found';
  };
  const findAddressById = (id, data) => {
    const location = data.find(item => item.LocationID === id);
    if (location) {
      const { address } = location;
      return address;
    }
    return 'address Not Found';
  };

  const getCustomSetting = () =>{
    if (decriptedOrganization && decriptedOrganization.custom_settings) {
      const customSettings = decriptedOrganization.custom_settings;

      // Parse custom settings and set state values accordingly
      Set_show_payroll(customSettings.show_payroll === 'true');
      Set_show_leave(customSettings.show_leave === 'true');
    }
  }

  const getManagers = () =>{
    if (decriptedOrganization && decriptedOrganization.users) {
      const customSettings = decriptedOrganization.users;
     
      const userList = customSettings.map((user, index) => ({
        id: user.id,
        name: user.name,
        email: user.Email,
        location: findLocationById(user.locId, decriptedOrganization.Locations),
        address: findAddressById(user.locId, decriptedOrganization.Locations)
        // location: user.Locations && user.Locations.length > 0 ? user.Locations[0].Location : 'N/A',
      }));    
      
      Setmanagers(userList);

    }

  }

  const getEmployees = ()=>{
    if (decriptedemployees) {
      const customSettings = decriptedemployees;
      const userList = customSettings.map((user, index) => ({
        id: user['Employee ID'],
        name: user.Name,
        email: user.Email,
        password: user.password,
        photo: user.Photo
      })); 

      SetemployeesForManagerPost(userList)

    



    }

  }



  useEffect(() => {
    if (isPlatinum) {
      getCustomSetting();
      getManagers();
      getEmployees();
    }
  
  }, [])
  // console.log(startupLocation)

  return ( 
    <div>
      <Location isStartup={isStartup} organizationLocations={startupLocation} organization={decriptedOrganization} isSubAdmin={isSubAdmin}/>
      {
        isPlatinum && !isSubAdmin && ( 
          <>
          <SubAdmin Locations={startupLocation} Managers={managers} employees={employeesForManagerPost} organization={decriptedOrganization}/>
          </>
        )
      }
      <OfficeTiming decriptedOrganization={decriptedOrganization} />
      

      {
        isPlatinum && show_leave && (
          <LeavePolicy />
        )
      }
      {
        isStartup && (
          <LeavePolicy />
        )
      }
      {
        isPlatinum && show_payroll && (
          <OrganizationPolicy organization={decriptedOrganization} />
        )
      }
      {
        isStartup && (
          <OrganizationPolicy organization={decriptedOrganization} />
        )
      }
    </div> 
  );
}

export default OrganizationSetting;
