import { EmployeeUrl } from "../model/url";
import { parseEmployeeData } from "../model/Employee";
import {AttendenceUrl} from '../model/url';
import {LeaveUrl} from '../model/url';

export async function EmployeesApiDataParsing(OrganizationID) {
    const url = EmployeeUrl(OrganizationID);
      const response = await fetch(url, {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data.body);
        const employees = parseEmployeeData(data.body);
        // console.log(employees);
        return data.body;
      } else {
        return false;
        
      } 
    
}
export async function AttendenceApiDataParsing(OrganizationID) {
  const url = AttendenceUrl(OrganizationID);
    const response = await fetch(url, {
      method: 'GET',
    });

    if (response.ok) {
      const data = await response.json();
      // console.log(data.body);
      return data.body;
    } else {
      return false;
       
    } 
  
}

export async function LeaveApiDataParsing(OrganizationID) {
  const url = LeaveUrl(OrganizationID);
    const response = await fetch(url, {
      method: 'GET',
    });

    if (response.ok) {
      const data = await response.json();
      // console.log(data.body);
      return data.body;
    } else {
      return false;
       
    } 
  
}