import React from 'react';
import Holidays from '../Holidays';
import { BsPersonAdd } from "react-icons/bs";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { BsJournalBookmarkFill } from "react-icons/bs";
import { BsCurrencyDollar } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

function EmptyData({organization}) {
    const navigate = useNavigate();
    const nameOfOrganization = organization.Name;
  return (
    <div className='p-10'>
      <div className='text-5xl font-bold text-black'>Hello, {nameOfOrganization} 👋</div>
      <p>Here's what's going on today</p>
      <div className=''>
      <div className='mt-10 w-full md:flex-1 bg-white p-4 shadow rounded-lg'>
        <div className='bg-gray-100 p-5'>
            <p className='font-bold text-gray-700'>Things to do</p>
        </div>
        <div className='bg-gray-200 h-0.5'></div>
        <div className='p-6 flex gap-2 items-center hover:bg-indigo-100 cursor-pointer hover:rounded-lg my-0.5'>
            <BsPersonAdd className='text-indigo-900 mr-1' style={{ height:"20", width:"20" }}/>
            <div className='flex flex-row items-center flex-grow'>
                <div className="flex-grow">Onboard Employees</div>
                <BsArrowRightCircleFill className='text-indigo-500 mr-5' style={{ height:"20", width:"20" }}/>
            </div>
        </div>

        <div className='bg-gray-200 h-0.5 mx-10'></div>
        <div className='p-6 flex gap-2 items-center hover:bg-indigo-100 cursor-pointer hover:rounded-lg my-0.5'>
            <BsJournalBookmarkFill className='text-indigo-900 mr-1' style={{ height:"20", width:"20" }}/>
            <div className='flex flex-row items-center flex-grow'>
                <div className="flex-grow">Implement Policies</div>
                <BsArrowRightCircleFill className='text-indigo-500 mr-5' style={{ height:"20", width:"20" }}/>
            </div>
        </div>
        
        <div className='bg-gray-200 h-0.5 mx-10'></div>
        <div className='p-6 flex gap-2 items-center hover:bg-indigo-100 cursor-pointer hover:rounded-lg my-0.5'>
            <BsCurrencyDollar className='text-indigo-900 mr-1' style={{ height:"20", width:"20" }}/>
            <div className='flex flex-row items-center flex-grow'>
                <div className="flex-grow">Set Payroll Methods</div>
                <BsArrowRightCircleFill className='text-indigo-500 mr-5' style={{ height:"20", width:"20" }}/>
            </div>
        </div>

      </div>
      <div className='mt-10 w-full md:flex-1 bg-white p-4 shadow rounded-lg'>
      <Holidays/>
      </div>
      </div>
      
        
    </div>
  )
}

export default EmptyData
