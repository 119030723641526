import React from 'react';
import { BsNewspaper } from "react-icons/bs";
import { CiEdit } from "react-icons/ci";
import { MdModeEditOutline } from "react-icons/md";
import { useSelector } from 'react-redux';
import { CheckImageOrLabelForEmployeeTable } from '../../Provider/checkImage';
import { Avatar } from 'primereact/avatar';
import { EncryptionAndDecryptionKeys } from '../../model/url';
import { decryptMydata } from '../../Provider/EncriptionAndDecription';
        
function AccountDetails({organization}) {
  const name = CheckImageOrLabelForEmployeeTable(organization);
  return (
    <div className='text-black bg-white w-90% p-0 shadow rounded-lg mt-4 flex'>
        <div className='flex-1 flex-col w-3/5 h-1/6 m-4'>
  <div className='p-3 flex items-center '>
    <div className='flex-row flex  w-16 h-16 sm:h-6 sm:w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 xl:h-16 xl:w-16 rounded-full bg-indigo-200  items-center justify-center'>
        <BsNewspaper size={25} className='text-indigo-700'/>
      {/* Add your icon component here */}
    </div>
    <h2 className='font-bold flex ml-5 text-3xl'>Account Details</h2>
  </div>
  <div className=' mr-16 ml-32 mb-20'>
    <div className='flex items-center my-2 py-2'>
        <div className='flex-1 font-bold text-black'>Email:</div>
        <div className='flex-1 text-gray-400'>{organization.Email}</div>
        {/* <div className='flex-0 flex-row cursor-pointer'><CiEdit/> Edit</div> */}

    </div>
    <div className="border-t border-gray-300"></div>
    <div className='flex items-center my-2 py-2'>
        <div className='flex-1 font-bold text-black'>Password:</div>
        <div className='flex-1 text-gray-400'>************************</div>
        <div className='flex-0 flex-col cursor-pointer items-center justify-center'>
          <CiEdit />
          <p>Edit</p>
          </div>

    </div>

  </div>
</div>

<div className='flex bg-blue-100 w-1/3 justify-center items-center h-auto'>
  <div className='flex flex-col items-center h-auto'>
    <div className='w-28 h-28 sm:h-6 sm:w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 xl:h-28 xl:w-28 relative'>
      <div className='rounded-full bg-indigo-500 flex items-center justify-center h-full relative cursor-pointer text-center'>
        {/* Updated flex classes */}
        {organization.Photo === '' ? (
          <Avatar
            label={name}
            style={{ backgroundColor: '#32317f', color: '#ffffff' }}
            className="mr-2"
            size='xlarge'
            shape="circle"
          />
        ) : (
          <Avatar
            image={organization.Photo}
            className="mr-2"
            size='xlarge'
            shape="circle"
          />
        )}

        {/* Red circle */}
        <div className='bg-blue-300 w-8 h-8 rounded-full absolute bottom-0 right-2 flex items-center justify-center cursor-pointer'>
          <MdModeEditOutline size={16} />
        </div>
      </div>
    </div>

    <div className='text-3xl font-medium text-center mt-5'>{organization.Name}</div>
  </div>
</div>


      
    </div>
  )
}

export default AccountDetails
