import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Toast } from 'primereact/toast';
import React, { useRef, useState, useEffect } from 'react';
import { IoIosCloseCircle } from 'react-icons/io';
import { decryptMydata } from '../../Provider/EncriptionAndDecription';
import { EncryptionAndDecryptionKeys } from '../../model/url';
import { inviteEmployee } from '../../Provider/InviteEmployee';
import { Dropdown } from 'primereact/dropdown';
import { EmployementTypeOptions } from '../../model/EmployeeConstants';
import { GenderTypeOptions } from '../../model/EmployeeConstants';
 
function AddEmployeePopUp({ onClose, locations, isStartup }) {

  const showFilterforLocations = locations.length >5 ? true : false;

  const handleEmployementTypeChange = (e) => {
    SetemployementType(e.value);
  }; 
  const handleGenderTypeChange = (e) => {
    setGender(e.value); 
  }; 

  const handleLocationChange = (e) => {
    setLocation(e.value);
  
  }; 
  // console.log(locations[0].Location_ID)
  const toast = useRef(null);
  const [startupLocationName, setStartupLocationName] = useState(locations[0].Location_name);
  const [startupLocationId, setStartupLocationId] = useState(locations[0].Location_ID);
  const locationText = isStartup ? startupLocationName : "Assign Company Location";

  const token = localStorage.getItem("token"); 
    const key = EncryptionAndDecryptionKeys();
    const decryptedToken = JSON.parse(decryptMydata(token, key));

    const myconvertedDateFormat = (attendanceDate) => {
      // Create a new Date object from the provided string
      const dateObject = new Date(attendanceDate);
    
      // Extract year, month, and day from the date object
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
      const day = dateObject.getDate().toString().padStart(2, '0');
    
      // Combine year, month, and day in the "yy-mm-dd" format
      const convertedDate = `${year.toString().slice(2)}-${month}-${day}`;
    
      return convertedDate;
    };

    const getLocationNameById = (locationId, locatuions) => {
      const location = locatuions.find(loc => loc.Location_ID === locationId);

   
      
      const status = location ? location.Location_name : "Location not found";
      // console.log('status is ========> '+status+' <=========')
      setOfficeAssigned(status);
      return status;
    };

  const showSuccess = () => {
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Employee has been added and an invitation has been sent to their email',
      life: 3000,
    });


   

    

    
    

    // Clear text field data
    setEmployeeId('');
    setName('');
    setEmail('');
    setPhone('');
    setDesignation('');
    setDepartment('');
    setSalary('');
    setDob('')
    setGender('');
    setLocation('');
    Setaddress('');
    SetemployementType('');
  };
  const showFailure = () => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: 'Something went Wrong while adding! Try again leter',
      life: 3000,
    });
  }



  const [employeeId, setEmployeeId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState();
  const [dob, setDob] = useState(null);
  const [mylocation, setLocation] = useState();
  const [officeAssigned, setOfficeAssigned] = useState('');
  const [designation, setDesignation] = useState('');
  const [department, setDepartment] = useState('');
  const [salary, setSalary] = useState('');
  const [address, Setaddress] = useState('');
  const [employementType, SetemployementType] = useState('');
  const [loading, setLoading] = useState(false);
  
    const generateRandomNumber = () => {
      const randomNumber = Math.floor(10000000 + Math.random() * 90000000);
      return randomNumber;
    }

    const pass = generateRandomNumber();

    // const dataForStartup = {
    //   "Employee ID": employeeId,
    //   "Department": department,
    //   "Address": "",
    //   "Date of Birth": ""+ myconvertedDateFormat(dob) + "",
    //   "DateofBirth": ""+ myconvertedDateFormat(dob) + "",
      
    //   "Designation": designation,
    //   "Email": email,
    //   "EmploymentStatus": "Inactive",
    //   "Gender":""+ gender + "",
    //   "JoiningDate": "",
    //   "Leaves": [
    //   ],
    //   "location":  ""+startupLocationName + "",
    //   "Name": name,
    //   "Office":""+ startupLocationId + "",
    //   "OrganizationID": decryptedToken,
    //   "password": ''+pass+'',
    //   "Phone": phone,
    //   "Photo": "",
    //   "Salary": salary
    //  };


  const data = {
    "Employee ID": employeeId,
    "Department": department,
    "Address": address,
    "Date of Birth": ""+ myconvertedDateFormat(dob) + "",
    "DateofBirth": ""+ myconvertedDateFormat(dob) + "",
    "EmployeementType": employementType,
    
    "Designation": designation,
    "Email": email,
    "EmploymentStatus": "Inactive",
    "Gender":""+ gender + "",
    "JoiningDate": "",
    "Leaves": [
    ],
    "location": isStartup ?  ""+startupLocationName + "" :  ""+ officeAssigned + "",
    "Name": name,
    "Office": isStartup ? ""+ startupLocationId + "" : ""+ mylocation + "",
    "OrganizationID": decryptedToken,
    "password": ''+pass+'',
    "Phone": phone,
    "Photo": "",
    "EmployeeExit": "",
    "EmployeeExitDate": "",
    "Salary": salary,
    'deviceinfo':[],
    'deviceRequestHistory':[],
    'token':""
   };

   const postEmployeeData= async ()=>{
    setLoading(true);
    //  console.log(data)
    const status = await  inviteEmployee({data});
    if(status === true){
      setLoading(false);
      showSuccess();
    }else if (status === false) {
      setLoading(false);
      showFailure();
    }

   }
 

   useEffect(() => {
    getLocationNameById(mylocation, locations);
   }, )


  const load = (e)  => {
    e.preventDefault();
    console.log('im pressed')
    
    postEmployeeData();
    

    // setTimeout(() => {
    //   console.log(data)
    //   setLoading(false);
    //   showSuccess();
    // }, 2000);
  };

 

  const handleAutoGenerate = (e) => {
    e.preventDefault();
    
    const randomId = Math.floor(100000 + Math.random() * 900000); // Generate a random 6-digit number
    setEmployeeId(randomId.toString()); // Set it as the Employee ID
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
      <Toast ref={toast} />
      <div className="bg-white rounded-lg shadow-lg sm:w-5/6 md:w-4/6 lg:w-2/6 relative h-5/6 overflow-y-auto">
        <button
          className="absolute top-2 right-2"
          onClick={onClose}
          style={{
            width: '24px',
            height: '24px',
          }}
        >
          <IoIosCloseCircle className="text-indigo-500" size={24} />
        </button>
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4">Add Employee</h2>
          <form onSubmit={load}>
            <div className="mb-1">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employeeId">
                Employee ID:
              </label>
              <input
                className="border rounded-md py-2 px-3 w-full h-10"
                required
                type="text"
                id="employeeId"
                name="employeeId"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
              />
              <div className='flex justify-end mr-2 mt-1'>
                <button className='text-indigo-500 text-xs' onClick={handleAutoGenerate}>
                  Auto Generate
                </button>
              </div>
            </div>
            <div className="mb-4">
  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="Employement Type">
    Employement Type:
  </label>
  <Dropdown
  className='w-full'
  value={employementType}
  options={EmployementTypeOptions}
  onChange={handleEmployementTypeChange}
  placeholder="Select an Employement Type"
  required
  />
</div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                Name:
              </label>
              <input
                className="border rounded-md py-2 px-3 w-full h-10"
                required
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-4">
  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
    Gender:
  </label>
  <Dropdown
  className='w-full'
  value={gender}
  options={GenderTypeOptions}
  onChange={handleGenderTypeChange}
  placeholder="Select Gender"
  required
  />
</div>

<div className="mb-4">
  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
    Date of birth:
  </label>
  <Calendar className='w-full h-10' value={dob} onChange={(e) => setDob(e.value)} dateFormat="yy-mm-dd" />
</div>

            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Email:
              </label>
              <input
                className="border rounded-md py-2 px-3 w-full h-10"
                required
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
                Phone:
              </label>
              <input
                className="border rounded-md py-2 px-3 w-full h-10"
                required
                type="phone"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                Address:
              </label>
              <input
                className="border rounded-md py-2 px-3 w-full h-10"
                required
                type="address"
                id="address"
                name="address"
                value={address}
                onChange={(e) => Setaddress(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="designation">
                Designation:
              </label>
              <input
                className="border rounded-md py-2 px-3 w-full h-10"
                required
                type="text"
                id="designation"
                name="designation"
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="department">
                Department:
              </label>
              <input
                className="border rounded-md py-2 px-3 w-full h-10"
                required
                type="text"
                id="department"
                name="department"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              />
            </div>
            <div className="mb-4">
  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="location">
    Location:
  </label>
  <Dropdown
  filter ={ showFilterforLocations}
  className='w-full' 
  value={mylocation}
  disabled = {isStartup}
  options={locations.map(loc => ({ label: loc.Location_name, value: loc.Location_ID }))}
  onChange={handleLocationChange}
  placeholder= {locationText}
  required={!isStartup}
  />
</div>
            {/* <div className="mb-4">
  <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="gender">
  Location:
  </label>
  <select
    className="border rounded-md py-2 px-3 w-full h-10"
    required
    id="location"
    name="location"
    value={mylocation}
    onChange={(e) => setLocation(e.target.value)}
  >
    <option value="" disabled>Assign Company Location</option>
    {locations.map((loc, index) => (
      <option key={index} value={loc.Location_ID}>
        {loc.Location_name}
      </option>
    ))}
  </select>
</div> */}
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="salary">
                Salary:
              </label>
              <input
                className="border rounded-md py-2 px-3 w-full h-10"
                required
                type="text"
                id="salary"
                name="salary"
                value={salary}
                onChange={(e) => setSalary(e.target.value)}
              />
            </div>
            <div className="card flex flex-wrap justify-end justify-content-center gap-3 mt-8">
              <Button type="submit" label="Add & Send Invite" icon="pi pi-check" loading={loading} />
            </div>
            <div className="flex justify-end"></div>
          </form>
        </div>
      </div>
    </div>
  );
}


export default AddEmployeePopUp;
