import L from "leaflet";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef, useState } from "react";
import { FeatureGroup, MapContainer, Marker, TileLayer } from "react-leaflet";
import { Toast } from 'primereact/toast';
import { EditControl } from "react-leaflet-draw";
import markerImg from '../../../Data/IMG/marker.png';
import { randomid } from "../../../Provider/generateRandomid";
import osm from "./osm-providers";
import { json } from "react-router-dom";
import { EncryptionAndDecryptionKeys } from "../../../model/url";
import { decryptMydata } from "../../../Provider/EncriptionAndDecription";
import { encriptmydata } from "../../../Provider/EncriptionAndDecription";
import { useNavigate } from 'react-router-dom';
import { updateAndEncryptOrProperty } from "../../../Provider/updateOrgStatusNewUser";

const markerIcon = new L.Icon({
  iconUrl: markerImg,
  iconSize: [40, 40],
  iconAnchor: [17, 46], //[left/right, top/bottom]
  popupAnchor: [0, -46], //[left/right, top/bottom]
});

const MarkersMap = ({lat, lng, organization, onClose, checkNewUserOrNot, setDone, ip, isNotAstartup}) => {
  // console.log(organization)
  const toast = useRef(null);
  const [center] = useState({ lat: lat, lng: lng });
  const ZOOM_LEVEL = 14;
  const mapRef = useRef(null);
  const [mapLayer, setmapLayer] = useState([]);
  const [location, setlocation]= useState();
  const [address, setaddress]= useState();
  const [loading, SetLoading]= useState(false);
  const orgid = organization["Organization ID"];
  const navigation = useNavigate();
  
 

  const showFailure = () => {
    toast.current.show({
      severity: 'error',
      summary: 'Error',
      detail: 'Something went Wrong while adding! Try again leter',
      life: 3000,
      zIndex: 2000, 
    });
  }

  const showSuccess = () => {
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'Location has been added and Now you can easily assign it to employees',
      life: 3000,
      zIndex: 2000, 
    });

  };

  const _onCreat = (e) =>{
    console.log(e);
    const {layerType, layer} =e;
    if (layerType ==="polygon") {
      const {_leaflet_id} = layer;

      setmapLayer((layers) => [
        ...layers,
        {id: _leaflet_id, latlngs: layer.getLatLngs()[0]},
    ]);

    }
  }
  const _onEdit = (e) =>{
    console.log(e);
    // const {
    //   layers : {_layers},
    // } = e;
    // Object.values(_layers).map(({ _leaflet_id, editing }) =>{
    //   setmapLayer(layers)=>
    //   layers.map((i) =>i.id ===_leaflet_id)
    //   ? {...i, latlngs: {...editing.latlngs[0]}}
    //   :i
    // );

    // });
  };
  const _onDelete = (e) =>{
    console.log(e);

  }

  useEffect(() => {
    // This will print the updated mapLayer to the console whenever it changes.


    // console.log(JSON.stringify(mapLayer, 0, 2));
  }, [mapLayer]);

  


  const data = {
    "Organization ID":orgid,
    "Status":"Active",
    "Location":location,
    "LocationID": ""+randomid()+"",
    'address':address,
    'ipaddress': isNotAstartup ?  ""+ip+"": "",
    "data": JSON.stringify(mapLayer, 0,2) 
  }
  // console.log(data)

  const postData = (Mydata) => {
    SetLoading(false);
    
      

    fetch('https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/organization', {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(Mydata),
})
  .then(response => response.json())
  .then(data => {
    const status = data["statusCode"]
    if (status === 200) {
      // const mystatus = updateAndEncryptOrProperty();

      SetLoading(false);
      showSuccess();
      onClose();
      setDone(true);
      // checkNewUserOrNot();
      // navigation('/');
      // window.location.reload();
    } else {
      showFailure();
      alert("Status is " + status + " Something went wrong. Could not signup right now. Try again later")
    }
  })
  .catch(error => {
    showFailure();
    SetLoading(false);
    console.error('Error:', error);
  });

  };

  const hitAPI = (e) => {
    // e.preventDefault(); 
    SetLoading(true);
    // console.log(data)
    postData(data)
  }


  return (
    
    <>
    <p className='mb-3 '>Mark your location premises and give it a name</p>
    <Toast ref={toast} />
            <MapContainer center={center}  zoom={ZOOM_LEVEL} ref={mapRef} style={{ height: "75vh", width: "100vh" }}>
            <FeatureGroup>
                <EditControl
                  position="topright"
                  onCreated={_onCreat}
                  onEdited={_onEdit}
                  onDeleted={_onDelete}
                  draw={
                    {
                    rectangle: false,
                    circle: false,
                    circlemarker: false,
                    marker: false,
                    polyline: false,                    }
                  }
                />
              </FeatureGroup>
              <TileLayer
                url={osm.maptiler.url}
                attribution={osm.maptiler.attribution}
              />
<Marker
        icon={markerIcon}
        position={[lat, lng]}
      />
            </MapContainer>
            <div className="mt-2">
                <input
                  placeholder="Name this Place (HQ, Site Office)"
                  id="location"
                  name="location"
                  type="Name"
                  autoComplete="Name"
                  required
                  value={location}
                  onChange={(e) => setlocation(e.target.value)}
                  className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900  sm:text-sm sm:leading-6"
                />
              </div>
              <div className="mt-2">
                <input
                  placeholder="Enter address of this Place (CUPERTINO, 1054 S De Anza Blvd CA 95129, United States)"
                  id="address"
                  name="address"
                  type="address"
                  autoComplete="address"
                  required
                  value={address}
                  onChange={(e) => setaddress(e.target.value)}
                  className="block  ml-[20.5rem] md:ml-0 w-[19rem] md:w-full rounded-md border border-gray-300 py-2 px-3 md:px-3 md:py-2 text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-indigo-900  sm:text-sm sm:leading-6"
                />
              </div>
              <button
              onClick={()=>hitAPI()}
                className={`transition-all duration-400 ease-in-out ml-3  ${
                  loading
                    ? 'w-14 h-14 bg-indigo-500 text-white rounded-full'
                    : 'px-10 py-3 bg-indigo-700 text-white rounded-lg cursor-pointer hover:bg-indigo-600 mt-3 disabled:bg-indigo-300'
                } hover:bg-indigo-700`}
                // onClick={MoveTODashboard}
                disabled={!location}
              >
                {loading ? (
                  <div className=" animate-spin flex items-center justify-center ">
                    <svg
                      className="w-6 h-6 text-white "
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                     
                  <circle
                    className='opacity-25 '
                    cx='12'
                    cy='12'
                    r='10'
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>

                    </svg>
                  </div>
                ) : (
                  'Save'
                )}
              </button>
              {/* <button className='px-10 py-3 bg-indigo-700 text-white rounded-lg cursor-pointer hover:bg-indigo-600 mt-3 disabled:bg-indigo-300'  disabled={!(location && JSON.stringify(mapLayer, null, 2))}>Save</button> */}
            {/* <pre>{
              JSON.stringify(mapLayer, 0,2)}</pre> */}
         
    </>
  );
};

export default MarkersMap;