import React, { useState, useRef, useEffect } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { InputSwitch } from 'primereact/inputswitch';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';

function UserSetting({organization}) {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setdisabled] = useState(true);

  const isPitb = organization.Name === "Punjab Information Technology Board (PITB)";
  // console.log(isPitb)

  const [switches, setSwitches] = useState({
    switch1: true,
    switch2: true,
    switch3: true,
    switch4: true,
    switch5: true,
    switch6: true,
  });

  const handleSwitchChange = (name) => (e) => {
    setSwitches({ ...switches, [name]: e.value });
    setLoading(true); // Disable the button when any switch is changed
  };

  const load = () => {
    setTimeout(() => {
      setLoading(false); // Enable the button
      showSuccess();
    }, 2000);
  };

  const showSuccess = () => {
    toast.current.show({
      severity: 'success',
      summary: 'Success',
      detail: 'New Employee setting has been saved',
      life: 3000,
    });
  };


  // useEffect(() => {
  //   // Check if any switch state has changed to enable the button
  //   const isAnySwitchChanged = Object.values(switches).some((value) => value !== true);
  //   setLoading(!isAnySwitchChanged); // Set loading to true if no switch state has changed, false otherwise
  // }, [switches]);

  return (
    <div className='bg-white w-90% p-4 shadow rounded-lg mt-4'>
      <Toast ref={toast} />
      <div className='p-3 flex items-center '>
        <div className='flex-row flex w-16 h-16 sm:h-6 sm:w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 xl:h-16 xl:w-16 rounded-full bg-indigo-200  items-center justify-center'>
          <BsFillPersonFill size={25} className='text-indigo-700' />
        </div>
        <h2 className='font-bold flex ml-5 text-3xl'>Employee Setting</h2>
      </div>
      <div className='p-4 bg-blue-100 mt-10 rounded-lg'>
        <div className='flex flex-wrap'>
          <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4'>
            <div className='flex items-center jus'>
              <p className='mr-2'>Allow employees to update profile pic?</p>
              <InputSwitch
              disabled = {isPitb}
                checked={switches.switch1}
                onChange={handleSwitchChange('switch1')}
              />
            </div>
          </div>
          <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4'>
            <div className='flex items-center'>
              <p className='mr-2'>Allow employees to apply for leave after the day?</p>
              <InputSwitch
                disabled = {isPitb}
                checked={switches.switch2}
                onChange={handleSwitchChange('switch2')}
              />
            </div>
          </div>
          <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4'>
            <div className='flex items-center'>
              <p className='mr-2'>Allow employees to apply for leave day before?</p>
              <InputSwitch
                disabled = {isPitb}
                checked={switches.switch3}
                onChange={handleSwitchChange('switch3')}
              />
            </div>
          </div>
          <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4'>
            <div className='flex items-center'>
              <p className='mr-2'>Allow employees to edit name, phone, email?</p>
              <InputSwitch
                disabled = {isPitb}
                checked={switches.switch4}
                onChange={handleSwitchChange('switch4')}
              />
            </div>
          </div>
          <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4'>
            <div className='flex items-center'>
              <p className='mr-2'>Allow Employees to mark attendace on any phone?</p>
              <InputSwitch
                disabled = {isPitb}
                checked={switches.switch5}
                onChange={handleSwitchChange('switch5')}
              />
            </div>
          </div>
          <div className='w-full sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/2 mb-4'>
            <div className='flex items-center'>
              <p className='mr-2'>Allow employees to mark attendace only when they are connected to office wifi?</p>
              <InputSwitch
                disabled = {isPitb}
                checked={switches.switch6}
                onChange={handleSwitchChange('switch6')}
              />
            </div>
          </div>
        </div>
      </div>
      {
        !isPitb && (
          <div className='flex justify-end mt-4'>
        <Button
          label='Save'
          icon='pi pi-check'
          loading={loading}
          onClick={load}
          disabled={disabled}
        />
      </div>

        )
      }
      
    </div>
  );
}

export default UserSetting;
