import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { FcCalendar } from "react-icons/fc";
import Caldendar from '../Data/IMG/Calendar.svg';
import { fetchHolidayData } from '../Provider/HolidaysCalculator';
import { HolidaysUrl } from '../model/url';
import { DataView } from 'primereact/dataview';
import { getCountryCode } from '../Provider/CountryCode';
import { HolidaysUrlForPakistan } from '../model/url';
import { Image, Shimmer } from 'react-shimmer'



function Holidays({organizationCountry}) {
  
    const [Holidays, SetHolidays] = useState();
    const [loading, Setloading] = useState(true);
    const [zeroHoliday, SetZeroHoliday] = useState(false);
    
    const getUpcomingHolidays = async (e) =>{
      const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

      const countryCode = getCountryCode(organizationCountry);
    
      const HolidayUrl = HolidaysUrlForPakistan(countryCode, currentYear, currentMonth);
    //   console.log(HolidayUrl)
    // const url = HolidaysUrl("US");
    const HolidaysComing = await fetchHolidayData(HolidayUrl);
    // console.log(HolidaysComing)
    if (HolidaysComing.length !== 0) {
        Setloading(false);
        SetHolidays(HolidaysComing);    
    }if (HolidaysComing.length === 0) {
      SetZeroHoliday(true)
    }
    

    }

    useEffect(() => {
        getUpcomingHolidays();
      }, []);

    const employeeBodyTemplate = (rowData) =>{
        const exsitingdate = rowData.date;
        // const parts = exsitingdate.split("-");
        // const day = parts[2];
        return (
            
          <div className='flex items-center'>
          <img src={Caldendar} alt='Calendar' width='24' height='24' />
          <span className='ml-2 text-black text-xl'>{exsitingdate}</span>
          <div className='ml-5'>
            
            <span className='text-black text-xl'>{rowData.name}</span>
            <div className=''>
              <span className='text-indigo-500 text-sm'>{rowData.type}</span>
            </div>
          </div>
        </div>
        
        );

    }
  return (
    <div>
        {loading ?(
          <div>
            <Shimmer className='w-full md:w-1/3 flex justify-content-center bg-white' />
          </div>
        
      
      ) : 
      
      (
      zeroHoliday ? (<div>
        <div className="flex flex-col items-center justify-center p-6 text-center">
        <div className="bg-indigo-200 rounded-full w-20 h-20 flex items-center justify-center">
          <FcCalendar className="text-indigo-700 text-6xl" />
        </div>
        <p className="text-2xl font-bold mt-4 mb-2">No upcoming Holidays</p>
        <p className="text-lg">
        But, come on, why limit joy to only a few days a year? Embrace happiness every day! 😄✨
        </p>
        {/* Add any other message or quote here */}
      </div>
      </div>) : (
      <DataTable style={{ surfaces:'var(--surface-ground)' }} value={Holidays}  rows={ 5 }  paginator={Holidays.length > 5 ? true : false}  size='large' tableStyle={{ minWidth: '50rem' }}>
      <Column body={employeeBodyTemplate} header="Upcoming Holidays"  style={{ width: '25%' }}></Column>
         </DataTable>))}
        
      
    </div>
  )
}

export default Holidays


// {...Holidays.length>5 ? paginator : null}