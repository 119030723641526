export function AnalyzeAttendanceData(attendanceData) {
  const today = new Date().toISOString().split('T')[0]; // Get today's date in the format 'YYYY-MM-DD'
  const todayAttendance = attendanceData.find(item => item.Date === today);
  
  if (!todayAttendance) {
      return {
          presentPercentage: 0,
          absentPercentage: 0,
          presentCount: 0,
          absentCount: 0,
          totalEmployees: 0
      };
  }

  const checkinData = todayAttendance.checkin;
  const totalEmployees = checkinData.length;
  let presentCount = 0;
  let absentCount = 0;

  checkinData.forEach((checkinItem) => {
      if (checkinItem.Status === 'Absent') {
          absentCount++;
      } else if (checkinItem.Status === 'Present' || checkinItem.Status === 'Late') {
          presentCount++;
      }
  });

  const presentPercentage = (presentCount / totalEmployees) * 100;
  const absentPercentage = (absentCount / totalEmployees) * 100;

  return {
      presentPercentage,
      absentPercentage,
      presentCount,
      absentCount,
      totalEmployees,
  };
}
