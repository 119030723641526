// Attendance.js
import React from 'react';
import AttendanceNewTable from '../components/attendence/attendanceNewTable';
import EmployeeSVG from '../Data/IMG/attendance.png'

function Attendance({attendance, status, employee}) {
  // console.log(attendance.length)
  return <div className='text-black'>
    <div className='text-black font-bold'>
      Attendance
    </div>
    {status === false  ? (<div>
      <div>
      <div className="bg-white p-4 shadow rounded-lg mt-5">
  <div className="">
    <div className=" w-full">
      <div className="">
        <div className="">
          <div className="">
            <div className="">
              <div className="flex max-[991px]:flex max-[991px]:flex-col max-[991px]:gap-y-16  gap-20 justify-between">
                <div className="w-full p-4 max-[991px]:max-w-[720px] sm:w-full sm:p-6 md:p-10 lg:w-[600px]">
                  <div className="mb-4">
                    <h2 className="text-3xl font-semibold md:text-5xl">Your workforce is your most valuable asset. Keep track of their presence!</h2>
                  </div>
                  <div className="mb-12">
                    <div className="flex flex-row items-center gap-x-4 max-[479px]:flex-row max-[479px]:justify-start max-[479px]:gap-y-[0px] sm:items-center">
                      <div className="text-lg text-[#414141]">No Attendance data available. Once your team start marking attendance thier data will display here</div>
                    </div>
                  </div>
                  {/* <a href="https://focus-template-bf49ac.webflow.io/blog-posts/the-productivity-mindset-cultivating-habits-for-success" className="inline-block cursor-pointer items-center self-center rounded-lg bg-indigo-900 px-6 text-center font-['DM_Sans',_sans-serif] text-lg font-bold text-white no-underline transition hover:bg-indigo-600 hover:outline-0 py-3">Onboard Employees</a> */}
                </div>
                <div className="h-[416px] w-full max-[991px]:items-center max-[991px]:justify-center sm:w-full lg:w-[618px]">
                  <img src={EmployeeSVG} alt="Employee Image" className="mx-auto inline-block h-full w-full max-w-none object-cover lg:max-w-[640px] rounded-2xl" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


    </div>
    </div>):(<div>
      <AttendanceNewTable attendance={attendance} length={20} employee={employee}/>

    </div>)}
    
    
  </div>;
}

export default Attendance;

