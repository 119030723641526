import { ProgressBar } from 'primereact/progressbar';
import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useState, useCallback } from 'react';
import { CgMenuRound } from 'react-icons/cg';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Transparent from './Data/IMG/transparent.png';
import { LastTwo, datas } from './Data/Sidebardata';
import Attendance from './Pages/Attendance';
import Dashboard from './Pages/Dashboard';
import Employees from './Pages/Employees';
import Leaves from './Pages/Leaves';
import Payroll from './Pages/Payroll';
import Setting from './Pages/Setting';
import { calculatePayroll } from './Provider/CalculatePayroll';
import { decryptMydata } from './Provider/EncriptionAndDecription';
import Content from './components/Content';
import MySidebar from './components/Sidebar';
import NewUser from './components/dashbaord/NewUser';
import { EncryptionAndDecryptionKeys } from './model/url';
import { clearPersistedData } from './redux/action';
import { getAllLocations } from './Provider/returnLocations';
import { EmployeesApiDataParsing } from './Provider/startApiData';
import { AttendenceApiDataParsing } from './Provider/startApiData';
import Device from './Pages/Device';
import { getDeviceRequestFromEmployees } from './Provider/GetDevicesRequest';
import { EmployeeDeviceInfo } from './Provider/CleanDeviceinfoEmployeeWise';
import { filterAttendanceForSubAdmins } from './Provider/filterAttendanceForSubAdmins';



export default function Home() {




  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [isTabletMode, setIsTabletMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const organization = useSelector((state) => state.or ? state.or.or : null);
  const AttendanceData = useSelector((state) => state.at ? state.at.at : null);
  const EmployeeData = useSelector((state) => state.em ? state.em.em : null);
  const LeaveData = useSelector((state) => state.le ? state.le.le : null);

  const [Employee, setEmployee] = useState([]);
  const [liveAttendance, setLiveAttendance] = useState([]);
  const [Devices, setDevices] = useState([]);

  const [mytoken, SetMyToken] = useState();
  

  const [isNewUser, setIsNewUser] = useState();
  const [isEmptyData, setEmptyData] = useState();
  const [isStartup, SetisStartup] = useState(false);
  const [isPlatinum, SetisPlatinum] = useState(false);
 
  const [payroll, Setpayroll] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const key = EncryptionAndDecryptionKeys();

    const decryptedOrganization = decryptMydata(organization, key);
    // const decryptedOrganization = decryptMydata(organization, key);
    const parsedOrganization = decryptedOrganization ? JSON.parse(decryptedOrganization) : "";
    const role = parsedOrganization.role ?? '';
    // console.log(role)
    const isSubAdmin = role === 'sub_admin' 
    // console.log('decryptedAttendanceData:', parsedOrganization);
   
    

    const decryptedAttendanceData = decryptMydata(AttendanceData, key);
    // const decryptedAttendanceData = decryptMydata(AttendanceData, key);
    const parsedAttendanceData = decryptedAttendanceData ? JSON.parse(decryptedAttendanceData) : "";
// console.log('decryptedAttendanceData:', parsedAttendanceData);
 
const decryptedEmployeeData = decryptMydata(EmployeeData, key);
// const decryptedEmployeeData = decryptMydata(EmployeeData, key);
const parsedEmployeeData = decryptedEmployeeData ? JSON.parse(decryptedEmployeeData) : "";

const decryptedLeaveData = decryptMydata(LeaveData, key);
// const decryptedLeaveData = decryptMydata(LeaveData, key);
const parsedLeaveData = decryptedLeaveData ? JSON.parse(decryptedLeaveData) : "";

const [show_payroll, Set_show_payroll] = useState(null);
const [show_leave, Set_show_leave] = useState(null);
const [show_attendance, Set_show_attendance] = useState(null);
const [show_total_employees, Set_show_total_employees] = useState(null);



const getCustomSetting = () =>{
  if (parsedOrganization && parsedOrganization.custom_settings) {
    const customSettings = parsedOrganization.custom_settings;

    // Parse custom settings and set state values accordingly
    Set_show_payroll(customSettings.show_payroll === 'true');
    Set_show_leave(customSettings.show_leave === 'true');
    Set_show_attendance(customSettings.show_attendance === 'true');
    Set_show_total_employees(customSettings.show_total_employees === 'true');
  }
}


const filterEmployeesByLocation = (employees, locationId) => {
  // console.log('id is : '+locationId)
 
  return employees.filter(employee => employee.Office === locationId);
}





useEffect(() => {
  const token = parsedOrganization['Organization ID'];
  const fecthEmployees = async () => {
    let EmployeeStatus = await EmployeesApiDataParsing(token); 
    const locationWiseEmployees = [];
   
    if (isSubAdmin) {
  
      const id = parsedOrganization.locId;
      // console.log(id)
   
      id.forEach(locationId => {
        const employeesAtLocation = filterEmployeesByLocation(EmployeeStatus, locationId);
        locationWiseEmployees.push(...employeesAtLocation);
    });
      // const locationbasedEmployees = filterEmployeesByLocation(EmployeeStatus,id);
       EmployeeStatus = locationWiseEmployees;
    
    }
    
    setEmployee(EmployeeStatus)
  }
  fecthEmployees();

}, [])

useEffect(() => {
  const token = parsedOrganization['Organization ID'];
  const fetchLiveAttendance = async () => {
    let attendanceStatus = await AttendenceApiDataParsing(token);
    const filteredAttendance = [];
    // console.log(attendanceStatus)

    if (isSubAdmin) {
      const subAdminEmployeeIds = Employee.map(emp => emp['Employee ID']);
      // console.log(subAdminEmployeeIds)
      // Filter attendance for sub-admin's employees only

      const data = cleanAttendance(attendanceStatus, subAdminEmployeeIds);
      
    
      // Iterate over each attendance entry
      
        // console.log(filteredAttendance)
        setLiveAttendance(data);
      // attendanceStatus = filteredAttendance;
    }
      // console.log(filteredAttendance)
     if (!isSubAdmin) {
      setLiveAttendance(attendanceStatus);
     }
    
  }

  fetchLiveAttendance();
}, [Employee]);

useEffect(() => {

}, [Employee, liveAttendance])




useEffect(() => {
  const token = parsedOrganization['Organization ID'];
    const fetchDevices = async () => {
      const devices = await getDeviceRequestFromEmployees(token);
      setDevices(devices);
      
    };

    fetchDevices();
  
}, []);


  // const id = decryptedOrganization.locId;
      // const locationbasedEmployees = filterEmployeesByLocation(EmployeeStatus,id);
      // EmployeeStatus = locationbasedEmployees;
      // // console.log(locationbasedEmployees)




    const checkIfDataAvailble=()=>{
      if (parsedEmployeeData === "" && parsedAttendanceData === "" && parsedLeaveData ==="") {
        return true;
      } else { 
        return true;
      }
    }

   
    const checkNewUserOrNot=()=>{

      // console.log(decryptedOrganization.isNewUser)
      
      const statys = parsedOrganization.isNewUser;
      if (statys === "true") {
        setIsNewUser(true)
        navigate("/onboarding");
        
      }else if (statys === "false") {
        // console.log("on true")
        setIsNewUser(false)
        
      }
     

    }
    // console.log()


    const isCacheValueAvailable = () => {
      const userData = localStorage.getItem('persist:ro'); // Get the value from local storage
      const expectedValue = '{"_persist":"{\\"version\\":-1,\\"rehydrated\\":true}"}'; // Correctly define the expected JSON string
      return userData === expectedValue;// Check if user data is not present

      
    };

    const isTokenAvailable = () => {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      // generateKey();
    
      // Check if token is empty or contains an empty string
      if (!token || token.trim() === "") {
        setIsLoading(false)
        // console.log('returning false')
        return false;
      } else {
        // setIsLoading(false)
        // console.log('returning true')
        return true;
      }
    };
    

   

    
    


  //load organization data
    const GetDataAndCreateModels = async (e) => {
      // console.log("hre")
      const mypackage = parsedOrganization["payment_details"]["package_name"];
 
   
      if (mypackage === "Token Talent Startups") {
        SetisStartup(true);
      } else if (mypackage === "Token Talent Platinum") {
        SetisPlatinum(true);
        SetisStartup(false);
      }
      setIsLoading(true); // Set loading to true when making the API request
      // console.log(AttendanceData)
    if (parsedEmployeeData!=='undefined') {
      const paydata = calculatePayroll(parsedEmployeeData);
      Setpayroll(paydata);

      setIsLoading(false)
    } else {
      
    }

    };

    const checkifuserdatavailable = () => {
      dispatch(clearPersistedData());
      localStorage.removeItem('token');
      window.location.reload();
      navigate("/login");
    };

  const handleResize = () => {
    setIsTabletMode(window.innerWidth <= 768);
  };

  const toggleSidebar = () => {
    setVisible(!visible);
  };

  const handleSidebarItemClick = () => {
    if (isTabletMode) {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (isPlatinum) {
      getCustomSetting();
    }
    handleResize();

    window.addEventListener('resize', handleResize);
  
    // console.log("wifi name is ===>"+wifiName)

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const checkToken = async () => {
      const tokenAvailable = isTokenAvailable();
      SetMyToken(tokenAvailable);
      if (tokenAvailable) {
        GetDataAndCreateModels();
        checkNewUserOrNot();
      } else {
        // console.log("not available")
        dispatch(clearPersistedData());
        // localStorage.removeItem('token');
        // // window.location.reload();
        navigate("/login");
        // checkifuserdatavailable();
      }
      setIsLoading(false); 
    };
    checkToken();

    // Cleanup function (optional)
    return () => {
      // Cleanup logic (if needed)
    };
  }, []); 




  return (
    <div>
   
      { isNewUser ? (<NewUser organization={parsedOrganization} checkNewUserOrNot={checkNewUserOrNot} isNotAStartup={!isStartup} />):(<div>
        {isLoading ? (
        <div className=' flex w-screen justify-center items-center h-screen'>
          <div className=' card h-1/4 w-1/5 space-y-5'>
          
        <img src={Transparent} alt="Token Talent Logo" className='w-auto h-auto max-w-full bg-indigo-900 rounded-lg' />

          <div className='m-5'>
          <ProgressBar mode="indeterminate" style={{ height: '6px'}}></ProgressBar>
          </div>
          </div>
          </div>

    ) : (
    <div className='bg-indigo-900 flex w-screen'>
      {isTabletMode && (
        <div className='h-1/4 m-0'>
          <button> 
            <CgMenuRound
              className='text-white mt-3 ml-4'
              onClick={toggleSidebar}
              size={24}
            />
          </button>
        </div> 
      )}

      {!isTabletMode && <MySidebar isStartup={isStartup} isPlatinum={isPlatinum} />}
      
      <Content>
        <Routes>
          <Route path="/" element={<Dashboard employee={Employee} attendance={liveAttendance} leave={parsedLeaveData} status={isEmptyData} organization={parsedOrganization} isPlatinum={isPlatinum} isStartup={isStartup}/>} />
          <Route path="/employees" element={<Employees employee={Employee} status={isEmptyData} organization={parsedOrganization} isStartup={isStartup}/>} />
          <Route path="/attendance" element={<Attendance employee={Employee} attendance={liveAttendance} status={isEmptyData}/>} />
          <Route path="/leaves" element={<Leaves leave={parsedLeaveData} employee={parsedEmployeeData} status={isEmptyData}/>} />
          <Route path="/setting" element={<Setting  isStartup={isStartup} isPlatinum={isPlatinum}/>} />
          <Route path="/payroll" element={<Payroll pay={payroll} status={isEmptyData}/>} />
          <Route path='/device' element={<Device devices={Devices} Employee={Employee} organization={parsedOrganization}/>}/>
        </Routes>
      </Content>
      <div className=''>
        
      <Sidebar
        visible={visible} 
        onHide={() => setVisible(false)}
      >
        <div className=''>
        
        <div>

          .023
        <img src={Transparent} alt="Token Talent Logo" className='w-auto h-auto max-w-full bg-indigo-900 rounded-lg' />
      </div>
      {
        isPlatinum ? (
          <ul className="py-3">
  {datas.map((data, index) => {
    // Check the custom settings to determine whether to render the item
    // Use the '&&' operator to conditionally render the item
    if (
      (data.to === "/payroll" && !show_payroll) ||
      (data.to === "/leaves" && !show_leave) ||
      (data.to === "/attendance" && !show_attendance) ||
      (data.to === "/employees" && !show_total_employees)
    ) {
      return null; // Do not render this item
    } else {
      return (
        <NavLink to={data.to} key={data.to}>
          <li
            className={`flex items-center p-3 my-1 text-black rounded-xl hover:bg-indigo-700 ${
              location.pathname === data.to ? "bg-indigo-500" : ""
            }`}
          >
            <div className="mr-2 text-lg">{data.icon}</div>
            <div>{data.text}</div>
          </li>
        </NavLink>
      );
    }
  })}
</ul>
        ) : (
          <ul className="py-3">
          {datas.map((data, index) => (
            // Exclude rendering the '/device' item if it's not platinum
            data.to !== "/device" && (
              <NavLink to={data.to} key={data.to}>
                <li
                  className={`flex items-center p-3 my-1 text-white rounded-xl hover:bg-indigo-700 ${
                    location.pathname === data.to ? "bg-indigo-500" : ""
                  }`}
                >
                  <div className="mr-2 text-lg">{data.icon}</div>
                  <div>{data.text}</div>
                </li>
              </NavLink>
            )
          ))}
        </ul>
        )
      }
    
      {/* <ul className="py-3">
        {datas.map((data, index) => (
          <NavLink to={data.to}  key={data.to}>
            <li
              className={`flex items-center p-3 my-1 text-black rounded-xl hover:bg-indigo-700 ${
                location.pathname === data.to ? 'bg-indigo-500' : ''
              }`}
            >
              <div className="mr-2 text-lg">{data.icon}</div>
              <div>{data.text}</div>
            </li>
          </NavLink>
        ))}
      </ul>  */}
      
      
      <div className="my-80"></div>
      

      
     
      <ul className="py-3">
      {LastTwo.map((data, index) => (
        
          <NavLink to={data.to}  key={data.to}>
            {/* <div className="flex-grow border-t border-gray-600"></div> */}
            
            <li
              className={`flex items-center p-3 my-1 text-black rounded-xl hover:bg-indigo-700 ${
                location.pathname === data.to ? 'bg-indigo-500' : ''
              }`}
              onClick={handleSidebarItemClick}
            >
              <div className="mr-2 text-lg">{data.icon}</div>
              <div>{data.text}</div>
            </li>
          </NavLink>
        ))}
      </ul>
    

        </div>
        
      </Sidebar>
      
      
      </div>
      
      
    </div>
    
    )}
      </div>)}
      
      
    
    </div>
  );
}

function cleanAttendance(attendanceStatus, subAdminEmployeeIds) {
  // console.log(attendanceStatus);
  // console.log(subAdminEmployeeIds);
  const filteredAttendance = [];
  attendanceStatus.forEach(entry => {
    const filteredCheckin = entry.checkin.filter(checkin => subAdminEmployeeIds.includes(checkin.EmployeeID));
    const filteredCheckout = entry.checkout.filter(checkout => subAdminEmployeeIds.includes(checkout.EmployeeID));

    // If either checkin or checkout exists for sub-admin employee, add the entry to filteredAttendance
    if (filteredCheckin.length > 0 || filteredCheckout.length > 0) {
        const filteredEntry = {
            checkin: filteredCheckin,
            checkout: filteredCheckout,
            Date: entry.Date,
            EIDNDATE: entry.EIDNDATE,
            OrganizationID: entry.OrganizationID
        };
        filteredAttendance.push(filteredEntry);
    }
});
return filteredAttendance;
}


