// employeeReducer.js
const initialState = [];
  
  const employeeReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_EMPLOYEE_DATA':
        return {
          ...state,
          em: action.payload,
        };
      default:
        return state;
    }
  };
   
  export default employeeReducer;

    