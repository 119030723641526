import React, { useState, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { IoAddCircle } from "react-icons/io5";
import EditPopup from './officePopUp';

function Location({isStartup, organizationLocations, organization, isSubAdmin}) {
    const [isPopupOpen, setPopupOpen] = useState(false);
  

    const openPopup = () => {  
      setPopupOpen(true);
    };
  
    const closePopup = () => {
      setPopupOpen(false);
    };
  return (
    <div className='text-black bg-white w-90% p-4 shadow rounded-lg w-full'>
      office locations
      <div className='relative flex flex-row overflow-x-auto'>
      <div className='flex item-center justify-center'>
          <button disabled={isStartup || isSubAdmin} onClick={openPopup} className='text-indigo-500  disabled:text-indigo-200 '>
          <IoAddCircle className='h-12 w-12' />
          </button>
        </div>
        {organizationLocations.map((location, index) => (
    <div key={index} className='m-2  items-center justify-center flex   bg-indigo-400 rounded-lg px-14 py-5 relative text-center shadow-lg'>
      <div className=' flex flex-col'>
      <p className='font-bold text-sm overflow-ellipsis'>{location.Location}</p>
      {/* <p className='text-xs mt-1 overflow-ellipsis'>{location.address}</p> */}
      </div>
      
      <button disabled={isStartup} className="absolute bottom-0 right-0 bg-indigo-500 rounded-lg p-3 text-white disabled:bg-indigo-200" onClick={() => openPopup(index)}>
        <FiEdit size={20} />
      </button>
    </div>
  ))}
       
        {isPopupOpen && <EditPopup onClose={closePopup} organization={organization}/>}
       
      </div>
    </div>
  );
}

export default Location;


 {/* <div className='m-2 p-28 bg-indigo-400 rounded-lg w-1/4 relative text-center shadow-lg'>
          <p className='font-bold'>{organizationLocations[0].Location}</p>
          <p className='text-xs mt-1'>{organizationLocations[0].address}</p>
          <button disabled={isStartup} className="absolute bottom-0 right-0 bg-indigo-500 rounded-lg p-3 text-white disabled:bg-indigo-200" onClick={openPopup}>
            <FiEdit size={20} />
          </button>
        </div> */}