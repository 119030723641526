
export function OrganizationUrl(email, password) {
    const organization = 'https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/organization?email='+email+'&password='+password;
    return organization; 
}
export function EmployeeUrl(OrganizationID){
    const EmployeeUrl = 'https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/employees?OrganizationID='+OrganizationID;
    return EmployeeUrl;

}
export function AttendenceUrl(OrganizationID){
    const AttendenceUrl = 'https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/attendance?OrganizationID='+OrganizationID;
    return AttendenceUrl;

}

export function LeaveUrl(OrganizationID){
    const LeaveUrl = 'https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/leave?OrganizationID='+OrganizationID;
    return LeaveUrl;

}

export function HolidaysUrl(countryCode){
    const holidayUrl = 'https://date.nager.at/api/v3/NextPublicHolidays/'+countryCode;
    return holidayUrl;

}
export function HolidaysUrlForPakistan(countryCode, year, month){
    const holidayUrl = 'https://calendarific.com/api/v2/holidays?&api_key=UW8IOmlIPfXDzDjVmXSpYjTw16cnyZBY&country='+countryCode+"&year="+year+"&month="+month;
    return holidayUrl;

}
export function addTimingUrl(status, id){
    const holidayUrl = 'https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/organization?organizationID='+id+"&Status="+status+'&timing=0';
    return holidayUrl;
}
export function addWorkingDaysUrl(status, id){
    const holidayUrl = 'https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/organization?organizationID='+id+"&Status="+status;
    return holidayUrl;
}
export function inviteEmployeeUrl(){
    const holidayUrl = 'https://0o5y2334o4.execute-api.us-east-1.amazonaws.com/start/email';
    return holidayUrl;
}

export function EncryptionAndDecryptionKeys() {
    const Keys = "@Token!234)DeltaPure";
    return Keys;
    
}

export function AddManagerUrl(id) {
    const Keys = "https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/organization?organizationID="+id+"&Status=Active&subadmin=0";
    return Keys;
    
}

export function GetallDevices(id) {
    const Keys = "https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/device?OrganizationID="+id;
    return Keys;
    
}

export function approveOrRejectDevice(id) {
    const Keys = "https://humshroqg7.execute-api.us-east-1.amazonaws.com/Dev/device?OrganizationID="+id;
    return Keys;
    
}