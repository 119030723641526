import React from 'react';
import Location from '../components/setting/location';
import UserSetting from '../components/setting/usersetting';
import OrganizationSetting from '../components/setting/organizationSetting'; // Corrected component name
import { TabView, TabPanel } from 'primereact/tabview';
import AccountDetails from '../components/setting/AccountDetails';
import { useSelector } from 'react-redux';
import { EncryptionAndDecryptionKeys } from '../model/url';
import { decryptMydata } from '../Provider/EncriptionAndDecription';

function Setting({isStartup, isPlatinum}) {
  const organization = useSelector((state) => state.or.or);
  const key = EncryptionAndDecryptionKeys();

  const decriptedOrganization = JSON.parse(decryptMydata(organization, key)) 
  return (
    
    <div>
      Account settings
      <div className="mt-4 rounded-lg"> 
            <TabView>
                <TabPanel header="Account Details">
                  <AccountDetails organization={decriptedOrganization} />
                   {/* <Location />  */}
                </TabPanel>
                <TabPanel header="Employee Settings">
                <UserSetting organization={decriptedOrganization}/>         
                </TabPanel>
                <TabPanel header="Organizational Settings">
                <OrganizationSetting isStartup={isStartup} isPlatinum={isPlatinum}/> 
                   
                </TabPanel>
            </TabView>
        </div>
     
    </div>
  );
}

export default Setting;
