import { Button } from 'bootstrap';
import React from 'react';
import { IoIosCloseCircle } from "react-icons/io";

function AddPoliciesPopup({onClose}) {
  return (
    <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
        <div className='bg-white rounded-lg shadow-lg w-2/6 relative'>
        <button
          className="absolute top-2 right-2"
          onClick={onClose}
          style={{
            width: '24px',
            height: '24px',
          }}
        >
          <IoIosCloseCircle className="text-indigo-500" size={24} />
        </button>
        <div className="p-4">
        <h2 className="text-2xl font-bold mb-4">Add Policy</h2>
        <div className='space-y-5'>
        <form>
        <input
                className="border rounded-md py-2 px-3 w-full"
                required
                type="text"
                id="employeeId"
                name="employeeId"
                placeholder='Enter the Title for Policy (Time, Leave, Holiday)'
              />
              <textarea
              className="mt-3 border  rounded-md py-2 px-3 w-full"
              required
              type="text"
              id="description"
              name="description"
              placeholder='Enter Description of the policy'
              rows={15}
              ></textarea>

            <button
            type='submit'
            className='text-white bg-indigo-700 hover:bg-indigo-500 cursor-pointer px-8 py-3 rounded-lg mt-5'
            >
                Submit
            </button>

        </form>

        </div>
        

        </div>
        </div>

    </div>
  )
}

export default AddPoliciesPopup
