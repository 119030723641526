export function getAllLocations(data) {
    // Check if data is an array and not empty
    if (!Array.isArray(data) || data.length === 0) {
      console.error('Invalid input data');
      return [];
    }
  
    // Transform the data into the desired format
    const transformedData = data.map((item) => ({
      'Location_name': item['Location'] || '',
      'Location_ID': item['LocationID'] || '',
    }));
  
    return transformedData;
  };