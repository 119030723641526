import { GetallDevices } from "../model/url";
import { approveOrRejectDevice } from "../model/url";

export async function getDeviceRequestFromEmployees(organizationID) {
    const url = GetallDevices(organizationID);
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      
      return data.body;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }


  export async function approveOrRejectDeviceRequestFromEmployees(organizationID, data) {
    const url = approveOrRejectDevice(organizationID);
    //  console.log(url)
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      });
    //   console.log(response)
      if (response.ok) {
        const resdata = await response.json();
      
    
        const status = resdata["statusCode"];
        if (status === 200) {
            
            return true;
        } else if (status !==200) {
            return false; 
        }
      }else{
        return false;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  


