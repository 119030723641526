import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Pages/Login';
import Home from './Home';
import NotFoundSection from './Pages/NotFoundSection';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<Home />} />
        <Route path="*" element={<NotFoundSection />} />

        {/* <Route  path="/" element={(isKeyStored)?(<Home /> ):(<Login/>)}></Route> */}
      </Routes>
    </Router>
  );
} 

export default App;   
