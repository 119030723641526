export function removeDuplicateEmployees(dataArray) {
    const employeeIds = new Set(); // To track unique Employee IDs
    const cleanData = [];
  
    for (const item of dataArray) {
      const employeeId = item['Employee ID'];
  
      // Check if the Employee ID is already in the Set
      if (!employeeIds.has(employeeId)) {
        employeeIds.add(employeeId); // Add it to the Set
        cleanData.push(item); // Add the item to the clean data array
      }
    }
  
    return cleanData;
  }
  