class ResponseModel {
    constructor(statusCode, headers, body, token, data) {
      this.statusCode = statusCode;
      this.headers = headers;
      this.body = body;
      this.token = token;
      this.data = data;
    }
  }
  
  class DataModel {
    constructor(Phone, Employees, Address, OrganizationID, Password, Status, Email, Name) {
      this.Phone = Phone;
      this.Employees = Employees;
      this.Address = Address;
      this.OrganizationID = OrganizationID;
      this.Password = Password;
      this.Status = Status;
      this.Email = Email;
      this.Name = Name;
    }
  }
  
  export function createResponseModel(jsonResponse) {
    return new ResponseModel(
      jsonResponse.statusCode,
      jsonResponse.headers,
      jsonResponse.body,
      jsonResponse.token,
      new DataModel(
        jsonResponse.data.Phone,
        jsonResponse.data.Employees,
        jsonResponse.data.Address,
        jsonResponse.data["Organization ID"],
        jsonResponse.data.Password,
        jsonResponse.data.Status,
        jsonResponse.data.Email,
        jsonResponse.data.Name
      )
    );
  }

  
