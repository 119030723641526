export function CheckImageOrLabel(userData) {
  try {
    // Check if userData is a string
    const dataObj = typeof userData === 'string' ? JSON.parse(userData) : userData;

    // Rest of your code remains unchanged

    // Check if 'Photo' is blank or null
    if (!dataObj.Photo || dataObj.Photo.trim() === '') {
      const name = dataObj.Name;
      const spaceIndex = name.indexOf(' ');
      if (spaceIndex !== -1 && name.length > spaceIndex + 1) {
        // Extract the first character and the character after the first space and convert to uppercase
        const initials = name.charAt(0).toUpperCase() + name.charAt(spaceIndex + 1).toUpperCase();
        return initials;
      }
    }
    // If 'Photo' is blank, null, or the name doesn't contain a space, return an empty string
    return '';
  } catch (error) {
    console.error('Error parsing userData:', error);
    return '';
  }
}


export function CheckImageOrLabelForEmployeeTable(userData) {
  // Parse the userData object
  try {
    // Check if 'Photo' is blank or null
    if (!userData.Photo || userData.Photo.trim() === '') {
      const name = userData.Name;
      const spaceIndex = name.indexOf(' ');
      if (spaceIndex !== -1 && name.length > spaceIndex + 1) {
        // Extract the first character and the character after the first space and convert to uppercase
        const initials = name.charAt(0).toUpperCase() + name.charAt(spaceIndex + 1).toUpperCase();
        return initials;
      }
    }
    // If 'Photo' is blank, null, or the name doesn't contain a space, return an empty string
    return '';
  } catch (error) {
    console.error('Error parsing userData:', error);
    return '';
  }
}

export function CheckImageOrLabelForDeviceTable(userData) {
  // Parse the userData object
  try {
    // Check if 'Photo' is blank or null
    if (!userData.photo || userData.photo.trim() === '') {
      const name = userData.name;
      const spaceIndex = name.indexOf(' ');
      if (spaceIndex !== -1 && name.length > spaceIndex + 1) {
        // Extract the first character and the character after the first space and convert to uppercase
        const initials = name.charAt(0).toUpperCase() + name.charAt(spaceIndex + 1).toUpperCase();
        return initials;
      }
    }
    // If 'Photo' is blank, null, or the name doesn't contain a space, return an empty string
    return '';
  } catch (error) {
    console.error('Error parsing userData:', error);
    return '';
  }
}
export function CheckImageOrLabelForAttendance(userData) {
  // Parse the userData object
  try {
    // Check if 'image' is blank or null
    if (!userData.image || userData.image.trim() === '') {
      const name = userData.name;
      const spaceIndex = name.indexOf(' ');
      if (spaceIndex !== -1 && name.length > spaceIndex + 1) {
        // Extract the first character and the character after the first space and convert to uppercase
        const initials = name.charAt(0).toUpperCase() + name.charAt(spaceIndex + 1).toUpperCase();
        return initials;
      }
    }
    // If 'image' is blank, null, or the name doesn't contain a space, return an empty string
    return '';
  } catch (error) {
    console.error('Error parsing userData:', error);
    return '';
  }
}

export function CheckImageOrLabelForManager(userData) {
  // Parse the userData object
  try {
    const name = userData.name;
      const spaceIndex = name.indexOf(' ');
      if (spaceIndex !== -1 && name.length > spaceIndex + 1) {
        // Extract the first character and the character after the first space and convert to uppercase
        const initials = name.charAt(0).toUpperCase() + name.charAt(spaceIndex + 1).toUpperCase();
        return initials;
      }
  } catch (error) {
    console.error('Error parsing userData:', error);
    return '';
  }
}
