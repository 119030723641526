import { addWorkingDaysUrl } from "../model/url";
import { addTimingUrl } from "../model/url";

export async function UpdateWorkingHours(OrganizationID, status, data) {
    const url = addWorkingDaysUrl(status, OrganizationID);
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      });
      if (response.ok) {
        const resdata = await response.json();
        const status = resdata["statusCode"];
        if (status === 200) {
            return true;
        } else if (status !==200) {
            return false;
        }
      }else{
        return false;
      }

     

    
  }

  export async function UpdateTimings(OrganizationID, status, data) {
    const url = addTimingUrl(status, OrganizationID);
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      });
      if (response.ok) {
        const resdata = await response.json();
        const status = resdata["statusCode"];
        if (status === 200) {
            return true;
        } else if (status !==200) {
            return false;
        }
      }else{
        return false;
      }

     

    
  }