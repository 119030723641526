// leaveReducer.js
const initialState = [];
  
  const leaveReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_LEAVE_DATA':
        return {
          ...state,
          le: action.payload,
        };
      default:
        return state;
    }
  };
   
  export default leaveReducer;
