// organizationReducer.js
const initialState = [];
  
  const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ORGANIZATION_DATA':
        return {
          ...state,
          or: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default organizationReducer;
   