// rootReducer.js
import { combineReducers } from 'redux';
import organizationReducer from './organizationReducer';
import employeeReducer from './Employee/EmployeeReducer';
import attendanceReducer from './Attendence/AttendanceReducer';
import leaveReducer from './Leave/LeaveReducer';
import storage from 'redux-persist/lib/storage';

const appReducer = combineReducers({
  or: organizationReducer,
  em: employeeReducer,
  at: attendanceReducer,
  le : 	leaveReducer
  // Add other reducers if needed
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_PERSISTED_DATA') {
    storage.removeItem('persist:ro')
    storage.removeItem('persist:token')
    const { em, at, le, or, ...rest } = state;

    // Return the new state
    return rest;
    // return {
    //   ...state,
    //   employee: {
    //     employees: [],
    //   },
    //   attendance: {
    //     attendance: [],
    //   },
    //   leave: {
    //     leave: [],
    //   },
      
    //   // Add other parts of your state here
    // };
  }
  return appReducer(state, action)
}

export default rootReducer;
 