export function combineLeaveDataWithEmployeeData(leaveData, employeeData) {
    // Create a mapping of Employee IDs to employee details for quick lookup
    const employeeMap = employeeData.reduce((map, employee) => {
      map[employee['Employee ID']] = employee;
      return map;
    }, {});
  
    // Initialize counters for approved, rejected, and pending leave requests
    let approvedCount = 0;
    let rejectedCount = 0;
    let pendingCount = 0;
  
    // Combine leave data with employee details and calculate counts
    const combinedData = leaveData.map((leave) => {
      const employeeID = leave['Employee ID'];
      const employeeDetails = employeeMap[employeeID];
  
      if (employeeDetails) {
        // Employee details found
        const leaveRequestStatus = leave['Status'];
  
        // Update the counts based on leave request status
        if (leaveRequestStatus === 'Approved') {
          approvedCount++;
        } else if (leaveRequestStatus === 'Rejected') {
          rejectedCount++;
        } else {
          // Assuming any status other than Approved or Rejected is "Pending"
          pendingCount++;
        }
  
        // Combine leave data with employee details
        return {
          ...leave,
          ...employeeDetails,
          leaveRequestStatus,
        };
      } else {
        // Employee details not found
        return {
          ...leave,
          leaveRequestStatus: 'Employee Details Not Found',
        };
      }
    });
  
    // Return the combined data and counts
    return {
      combinedData,
      approvedCount,
      rejectedCount,
      pendingCount,
    };
  }