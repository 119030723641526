import { IoIosCloseCircle } from 'react-icons/io';
import { getFullDayName } from '../../Provider/getDayName';
import { Button } from 'primereact/button';
import React, { useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';

function AddHolidayPopup({ onClose, date }) {
    const holidayTypes = [
        { label: 'Public', value: 'public' },
        { label: 'National', value: 'national' },
        { label: 'Religious', value: 'religious' },
        { label: 'Cultural', value: 'cultural' },
        // Add more holiday types as needed
      ];
    const [loading, setLoading] = useState(false);
    const [selectedHolidayType, setSelectedHolidayType] = useState(null);
    const handleHolidayTypeChange = (e) => {
        setSelectedHolidayType(e.value);
      };

    const formattedDate = date.toLocaleDateString();
    const day = getFullDayName(formattedDate);

    return(
<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
  
      <div className="bg-white rounded-lg shadow-lg sm:w-4/6 md:w-4/6 lg:w-1/5  relative">
        <button
          className="absolute top-2 right-2"
          onClick={onClose}
          style={{
            width: '24px',
            height: '24px',
          }}
        >
          <IoIosCloseCircle className="text-indigo-500" size={24} />
        </button>
        <div className="p-4">
          <h2 className="text-2xl font-bold mb-4">Add Holiday</h2>
         <p>Do you want to add holiday on:</p>
         <div className='bg-gray-200 p-3 rounded-lg mt-2'>
            Date:
            <p className='text-indigo-500'>
                {formattedDate}
            </p>
         </div>
         <div className='bg-gray-200 p-3 rounded-lg mt-2'>
            Day:
            <p className='text-indigo-500'>
                {day}
            </p>
         </div>
         <div className='bg-gray-200 p-3 rounded-lg mt-2'>
            Holiday Type:
            <div>
            <Dropdown className='w-full mt-1'
              value={selectedHolidayType}
              options={holidayTypes}
              onChange={handleHolidayTypeChange}
              placeholder="Select a Holiday Type"
              required
            />
            </div>
            
          </div>
         <div className="card flex flex-wrap justify-end justify-content-center gap-3 mt-8">
              <Button type="submit" label="Set Holiday" icon="pi pi-check" loading={loading} />
            </div>
     
        </div>
      </div>
    </div>
  );
    
}
export default AddHolidayPopup;