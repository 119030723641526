import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';


function Box ({ number, chartComponent, isMobiletMode, isTabletMode, labels, dataProp, customColors  }) {
  const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    

    useEffect(() => {
      if (!isMobiletMode && !isTabletMode) {
        const documentStyle = getComputedStyle(document.documentElement);
        const backgroundColors = customColors.map((color) =>
          documentStyle.getPropertyValue(color)
        );
        const hoverBackgroundColors = customColors.map((color) =>
          documentStyle.getPropertyValue(`${color}`)
        );
        const data = {
          labels: labels,
          datasets: [
            {
              data: dataProp,
              backgroundColor: backgroundColors,
              hoverBackgroundColor: hoverBackgroundColors,
            },
          ],
        };
        const options = {
          cutout: '60%',
          plugins: {
            legend: {
              display: false, // Set to false to hide the labels on top of the chart
            },
          },
        };
    
        setChartData(data);
        setChartOptions(options);
      }
    }, [labels, dataProp, isMobiletMode, isTabletMode, customColors]);
    

  return (
    <div className='w-screen min-w-[150px]'>
      {
        isMobiletMode && <div className='bg-white  p-4 shadow rounded-lg'>
        <div className='text-2xl font-bold mb-2'>{number}</div>
        {chartComponent}
      </div>
      }
      {
        !isMobiletMode && !isTabletMode && <div className='bg-white  p-4 shadow rounded-lg '>
          <div className='flex'>
          <div className='text-2xl font-bold mb-2'>{number}</div>
            <div className='w-4/5 pl-36 '>
              <Chart type='doughnut' data={chartData} options={chartOptions} />
            </div>

          </div>
        {chartComponent}
      </div>
      }
      {
        isTabletMode && <div className='bg-white  p-4 shadow rounded-lg'>
        <div className='text-2xl font-bold mb-2'>{number}</div>
        {chartComponent}
      </div>
      }
     
  </div>
  )
};
 
export default Box;
