import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useState, useRef } from "react";
import { EmployeeDeviceInfo } from "../Provider/CleanDeviceinfoEmployeeWise";
import { Avatar } from 'primereact/avatar';
import { CheckImageOrLabelForDeviceTable } from '../Provider/checkImage';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { approveOrRejectDeviceRequestFromEmployees } from '../Provider/GetDevicesRequest';
import { Toast } from 'primereact/toast';

function Device({devices, Employee, organization}) {
    const toast = useRef(null);
   

    const oid = organization['Organization ID'];
    // console.log(oid)

    const cleanedDeviceInfo = EmployeeDeviceInfo( devices, Employee );

    const showSuccess = () => {
        toast.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Device status hass been updated',
          life: 3000,
        });
    }

    const showFailure = () => {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Something went Wrong while adding! Try again leter',
          life: 3000,
        });
      }

    

   
        
    const handleStatusChange = async (value, rowData, options) => {
        // console.log(newStatus)
        // console.log(rowData)
       

        if (value === 'approved' || value === 'rejected') {
            const deviceInfo = {
                brand: rowData.newDeviceBrand,
                id: rowData.newDeviceId,
                model: rowData.newDeviceModel,
                token: rowData.newDeviceToken
            };

            const updatedData = {
                status: value,
                deviceinfo: [deviceInfo]
            };

            // Do something with the updated data, like sending it to an API or storing it in state
            const status = await approveOrRejectDeviceRequestFromEmployees(oid, updatedData);
            if (status) {
                showSuccess();
                options.editorCallback(value)

                
            }else{
                showFailure()
            }
            // console.log(status)
            
            // console.log(updatedData);
         }
    };




    const employeeBodyTemplate = (rowData) =>{
        const name = CheckImageOrLabelForDeviceTable(rowData)
        // console.log(name)
        return (
            <div className='flex items-center'>
                {rowData.photo === '' ? 
                <Avatar 
                label={name}
                style={{ backgroundColor: '#32317f', color: '#ffffff' }}
                className="mr-2" size="large" shape="circle" /> : 
                <Avatar 
                image={rowData.photo}
                className="mr-2" size="large" shape="circle" />}
                <div className="flex flex-col">
                    <span className='text-black'>{rowData.name}</span>
                    <span className="text-indigo-500 text-xs">{rowData.email}</span>
                    <span className="text-gray-500 text-xs">{rowData.phone}</span>
                    <span className="text-gray-500 text-xs">{rowData.department}</span>

                </div>
            </div>
        );
    
    }

    const oldBodyTemplate = (rowData) =>{
        return (
            <div className='flex items-center'>
                <div className="flex flex-col">
                    <span className='text-black'>{rowData.oldDeviceId}</span>
                    <span className="text-indigo-500 text-xs">{rowData.oldDeviceBrand}</span>
                    <span className="text-gray-500 text-xs">{rowData.oldDeviceModel}</span>
                </div>
            </div>
        );
    
    }

    const newBodyTemplate = (rowData) =>{
        return (

            <div className='flex items-center'>
                <div className="flex flex-col">
                    <span className='text-black'>{rowData.newDeviceId}</span>
                    <span className="text-indigo-500 text-xs">{rowData.newDeviceBrand}</span>
                    <span className="text-gray-500 text-xs">{rowData.newDeviceModel}</span>
                </div>
            </div>
        );
    
    }

    const statusBodyTemplate = (rowData) => {
        return <Tag value={rowData.status} severity={getSeverity(rowData.status)} />;
    };
    const statusEditor = (options ) => {

        // const { value, rowData, onChange, options } = props;
        const rowData = options.rowData;
        // console.log(options)
        return (
            <Dropdown
                value={options.value}
                options={statuses}
                onChange={(e) => handleStatusChange(e.value ,rowData, options)}
                placeholder="Select a Status"
                itemTemplate={(option) => {
                    return <Tag value={option} severity={getSeverity(option)}></Tag>;
                }}
            />
        );
            };

    const [statuses] = useState(["approved", 'rejected',  "Pending"]);

    const getSeverity = (status) => {
        switch (status) {
            case 'rejected':
                return 'danger';

            case 'approved':
                return 'success'; 

            case 'Pending':
                return 'warning';
        }
    };
  return (
    <div>
         <Toast ref={toast} />
        <div className='text-black font-bold'>
            Device Requests
                <div className='w-full'>
                <div className="m-0 bg-white p-4 shadow rounded-lg mt-5">
            <DataTable value={cleanedDeviceInfo}    dataKey='employeeId' showGridlines resizableColumns  size={'small'} 
                    globalFilterFields={['name', 'email', 'employeeId']}  emptyMessage="No Devices change request by employee found">
                        
                        
                        <Column field='date' header="Date"  style={{ minWidth: '2rem' }}/>
                        <Column  body={employeeBodyTemplate} header="Employee"  style={{ minWidth: '2rem' }}/>
                        <Column body={oldBodyTemplate} header="Old Device"  style={{ minWidth: '12rem' }}/>
                        <Column body={newBodyTemplate} header="New Device"  style={{ minWidth: '12rem' }}/> 
                        <Column field="status" editor={(options, props) => statusEditor(options, props)} header="Action"   style={{ minWidth: '8rem' }} body={statusBodyTemplate}/>
               

               
            </DataTable>
        </div>


                </div>

            </div>

        </div>
  )
}

export default Device
