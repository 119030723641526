import React from 'react';
import classNames from 'classnames';

export default function Content({ children }) {
  const isTabletMode = window.innerWidth <= 768; // Adjust this width as needed

  const contentClasses = classNames(
    'bg-gray-50',
    'p-5',
    'content-container',
    'overflow-auto',
    'rounded-2xl',
    'shadow',
    'mb-5',
    
    {
      'w-10/12': !isTabletMode, // Apply this width if not in tablet mode
      'w-full': isTabletMode,
      'mt-12': isTabletMode, 
      'mr-10': isTabletMode,
      'mr-5':!isTabletMode,
      'mt-5':!isTabletMode,
      'ml-0':isTabletMode,
         // Apply this width if in tablet mode
      'h-[calc(100vh-25px)]': true, // Always apply this height
    }
  );

  return (
    <div className={contentClasses}>
      {children}
    </div>
  );
}
