export function calculatePayroll(employeeData) {
  let totalMonthlyPayroll = 0;
  let totalAnnualPayroll = 0;
  let highestPayrollDepartment = '';
  let lowestPayrollDepartment = '';
  let highestPayrollAmount = 0;
  let lowestPayrollAmount = Infinity; // Initialize to a high value

  // Create a mapping of departments to accumulate payroll amounts
  const departmentPayrollMap = {};

  // Iterate through the employee data
  employeeData.forEach((employee) => {
    const salary = parseFloat(employee.Salary.replace(',', '')); // Assuming Salary is in a format like "40,000"

    // Calculate monthly and annual payroll
    totalMonthlyPayroll += salary;
    totalAnnualPayroll += salary * 12;

    // Accumulate payroll amounts by department
    const department = employee.Department;
    if (department) {
      if (!departmentPayrollMap[department]) {
        departmentPayrollMap[department] = 0;
      }
      departmentPayrollMap[department] += salary;
    }

    // Update highest and lowest payroll department
    if (salary > highestPayrollAmount) {
      highestPayrollAmount = salary;
      highestPayrollDepartment = department;
    }
    if (salary < lowestPayrollAmount) {
      lowestPayrollAmount = salary;
      lowestPayrollDepartment = department;
    }
  });

  // Format amount values with commas for readability
  const formatWithCommas = (value) => value.toLocaleString();

  // Convert department payroll map to an array of objects with formatted amounts
  const departmentPayrollList = Object.entries(departmentPayrollMap).map(
    ([department, amount]) => ({
      department,
      amount: formatWithCommas(amount), // Format the amount with commas
    })
  );

  return {
    totalMonthlyPayroll: formatWithCommas(totalMonthlyPayroll), // Format totalMonthlyPayroll
    totalAnnualPayroll: formatWithCommas(totalAnnualPayroll), // Format totalAnnualPayroll
    highestPayrollDepartment,
    highestPayrollAmount: formatWithCommas(highestPayrollAmount), // Format highestPayrollAmount
    lowestPayrollDepartment,
    lowestPayrollAmount: formatWithCommas(lowestPayrollAmount), // Format lowestPayrollAmount
    departmentPayrollList,
  };
}
