// attendanceReducer.js
const initialState = [];
  
  const attendanceReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ATTENDANCE_DATA':
        return {
          ...state,
          at: action.payload,
        };
      default:
        return state;
    }
  };
   
  export default attendanceReducer;

    