import React, { useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { CustomerService } from '../service/CustomerService';
import { Button } from 'primereact/button';
import AddEmployeePopUp from './AddEmployeePopUp';
import ImportEmployee from './ImportEmployee';
import { Avatar } from 'primereact/avatar';
import { IoIosCloseCircle } from 'react-icons/io';
import { CheckImageOrLabelForEmployeeTable } from '../../Provider/checkImage'; 
import { GenerateRepresentitives } from '../../Provider/GenerateRepresentitives';
import { EmployementTypeOptions } from '../../model/EmployeeConstants';
import { GenderTypeOptions } from '../../model/EmployeeConstants';
import { Calendar } from 'primereact/calendar';
import { EmployeeExitTypeOptions } from '../../model/EmployeeConstants';

function EmployeesTable({employee, location, isStartup}) {

    // console.log(employee)
     
   
    
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isAddCsvPopupOpen, setAddCsvPopupOpen] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [editedEmployee, setEditedEmployee] = useState(null);
    const [startupLocationName, setStartupLocationName] = useState(location[0].Location_name);
    const locationText = isStartup ? startupLocationName : "Assign Company Location";
    // console.log(startupLocationName)
  


   


    useEffect(() => {
      if (selectedEmployee) {
    
        setEditedEmployee({ ...selectedEmployee });
        setname(selectedEmployee.Name || '');
        setEmail(selectedEmployee.Email || '');
        setGender(selectedEmployee.Gender || '');
        setdob(selectedEmployee.DateofBirth || null);
        setPhone(selectedEmployee.Phone || '');
        setAddress(selectedEmployee.Address || '');
        setdesignation(selectedEmployee.Designation || '');
        setdepartment(selectedEmployee.Department || '');
        setnewlocation(selectedEmployee.location || '');
        setsalary(selectedEmployee.Salary || '');
        setEmpType(selectedEmployee.EmployeementType || '')
        setIsDirty(false); // Reset isDirty when a new employee is selected
      } else {
        setEditedEmployee(null);
      }
    }, [selectedEmployee]);



   
    const [myname, setname]= useState(selectedEmployee?.Name || null);
    const [EmployeeExit, setEmployeeExit]= useState(selectedEmployee?.EmployeeExit || null);
    const [EmpType, setEmpType]= useState(selectedEmployee?.Email || null);
    const [Gender, setGender]= useState(selectedEmployee?.Gender || null);
    const [dob, setdob]= useState(null);
      const [email, setEmail]= useState(selectedEmployee?.Email ||  null);
        const [phone, setPhone]= useState(selectedEmployee?.Phone || null);
             const [address, setAddress]= useState(selectedEmployee?.Address || null);
                  const [designation, setdesignation]= useState(null);
                       const [department, setdepartment]= useState(selectedEmployee?.Department || null);
                            const [newlocation, setnewlocation]= useState(selectedEmployee?.location || null);
                                 const [salary, setsalary]= useState(selectedEmployee?.Salary || null);
                                 const [isDirty, setIsDirty] = useState(false);


                                 const handleInputChange = (e) => {
                                  const { name, value } = e.target;
                              
                                  // Update the corresponding state based on the input field name
                                  switch (name) {
                                    case 'Name':
                                      setname(value);
                                      break;
                                    case 'Email':
                                      setEmail(value);
                                      break;
                                    case 'Gender':
                                      setGender(value);
                                      break;
                                    case 'Phone':
                                      setPhone(value);
                                      break;
                                    case 'Address':
                                      setAddress(value);
                                      break;
                                    case 'Location':
                                      setnewlocation(value);
                                      break;
                                    case 'Department':
                                      setdepartment(value);
                                      break;     
                                    case 'Designation':
                                      setdesignation(value);
                                      break;
                                    case 'Dob':
                                      setdob(value);
                                      break;      
                                    case 'EmpType':
                                      setEmpType(value);
                                      break;
                                      
                                    case 'salary':
                                      setsalary(value);
                                      break;
                                    case 'EmployeeExit':
                                      setEmployeeExit(value);
                                      break;

                                    // ... repeat for other input fields
                                    default:
                                      break;
                                  }
                              
                                  // Set isDirty to true when any field changes
                                  setIsDirty(true);
                                };
     

    const renderSidebar = () => {
      
  
    
        if (selectedEmployee) {
            // console.log(selectedEmployee)
            const name = CheckImageOrLabelForEmployeeTable(selectedEmployee)
          return (
            <div className={`bg-transparent fixed inset-y-0 right-0 lg:w-1/3 sm:w-5/6 md:w-4/6 shadow-lg transition-transform  transform ${sidebarVisible ? 'translate-x-0' : 'translate-x-full'} `}>
                <button
          className="absolute top-2 right-2"
          onClick={closeSidebar}
          style={{
            width: '24px',
            height: '24px',
          }}
        >
          <IoIosCloseCircle className="text-indigo-500" size={24} />
        </button>
        {/* justify-between */}
        {/* space-y-5  */}
        <div className="flex flex-col  h-screen px-10 py-10 bg-indigo-100 rounded-tl-lg rounded-bl-lg overflow-y-auto">
            
          <div className='flex flex-col items-center text-center'>
          {selectedEmployee.Photo === '' ? 
                <Avatar 
                label={name}
                style={{ backgroundColor: '#32317f', color: '#ffffff' }}
                className="" size="large" shape="circle" /> : 
                <Avatar 
                image={selectedEmployee.Photo}
                className="" size="large" shape="circle" />}
          <p>{selectedEmployee["Employee ID"]}</p>
          </div>
        
         <p className='text-md font-bold mb-5 mt-5'>Basic info:</p>
         <label className="text-blue-700 text-sm mb-1" htmlFor="designation">
                Name:
              </label>
          <input type="text" name="Name" value={myname}  placeholder="Enter Name" onChange={handleInputChange}  className="px-3 py-3 rounded-md w-full " />
          <label className=" text-blue-700 text-sm mb-1 mt-2" htmlFor="designation">
                Gender:
              </label>
          <Dropdown
          showClear
            name='Gender'
  className='w-full '
  value={Gender}
  options={GenderTypeOptions}
  onChange={handleInputChange}
  placeholder="Select Gender"
  required
  />
  <label className=" text-blue-700 text-sm mb-1 mt-2" htmlFor="designation">
                Date of Birth:
              </label>
  <Calendar className='w-full'  name="Dob"  placeholder={dob} value={dob} onChange={(e) => handleInputChange} dateFormat="yy-mm-dd" />
  <p className='text-md font-bold mb-5 mt-5'> Contact info:</p>
  <label className=" text-blue-700 text-sm mb-1" htmlFor="designation">
                Email:
              </label>
            <input type="text" name="Email" value={email} placeholder="Enter a Email" onChange={handleInputChange} className=" px-3 py-3  rounded-md w-full" />
            <label className=" text-blue-700 text-sm mb-1 mt-2" htmlFor="designation">
                Phone:
              </label>
            <input type="text" name="Phone" value={phone} placeholder="Enter a Phone" onChange={handleInputChange} className=" px-3 py-3  rounded-md w-full" />
            <label className=" text-blue-700 text-sm mb-1 mt-2" htmlFor="designation">
                Address:
              </label> 
            <input type="text" name="Address" value={address} placeholder="Enter a Address" onChange={handleInputChange} className=" px-3 py-3  rounded-md w-full" /> 
            {/* <input type="text" name="Email" value={selectedEmployee.EmploymentStatus} placeholder="Email" className="mt-2 px-3 py-3  rounded-md w-full" /> */}
  <p className='text-md font-bold mb-5 mt-5'>Employement info:</p> 
  <label className=" text-blue-700 text-sm mb-1 mt-2" htmlFor="designation">
  Company Location Assigned:
              </label>
  <Dropdown
  showClear
            name='Location'
            disabled = {isStartup}
  className='w-full '
  value={newlocation}
  options={location.map(loc => ({ label: loc.Location_name, value: loc.Location_ID }))}
  onChange={handleInputChange}
  placeholder={locationText}
  required
  /> 
    <label className=" text-blue-700 text-sm mb-1 mt-1" htmlFor="designation">
    Designation:
              </label>
   <input type="text" name="Designation" value={designation} placeholder="Enter a Designation" onChange={handleInputChange} className="px-3 py-3  rounded-md w-full" /> 
   <label className=" text-blue-700 text-sm mb-1 mt-2" htmlFor="designation">
   Employement Type:
              </label>
   <Dropdown
  showClear
            name='EmpType'
  className='w-full '
  value={EmpType}
  options={EmployementTypeOptions}
  onChange={handleInputChange}
  placeholder='Select an Employement Type'
  required
  /> 
     <label className=" text-blue-700 text-sm mb-1 mt-2" htmlFor="designation">
     Department:
              </label>
  <input type="text" name="Department" value={department} placeholder="Enter a Department Name" onChange={handleInputChange} className=" px-3 py-3  rounded-md w-full" /> 
 
            {/* <input type="text" name="Email" value={selectedEmployee.JoiningDate} placeholder="Email" className="mt-2 px-3 py-3  rounded-md w-full" />   */}
            <label className=" text-blue-700 text-sm mb-1 mt-2" htmlFor="designation">
            Salary:
              </label>
             
            <input type="text" name="salary" value={salary} placeholder="Enter a Salary amount" onChange={handleInputChange} className=" px-3 py-3  rounded-md w-full" />
            <p className='text-red-500 text-md font-bold mb-5 mt-8'>Employee Exit:</p>
            <Dropdown
  showClear
            name='EmployeeExit'
  className='w-full '
  value={EmployeeExit}
  options={EmployeeExitTypeOptions}
  onChange={handleInputChange}
  placeholder='Select an Employement exit Type'
  required
  /> 
           
            
            <button className={`mt-4 bg-indigo-500 text-white px-4 py-4 shadow-lg rounded-md ${isDirty ? '' : 'hidden'}`}>Update</button>
        </div>
      </div>
          );
        }
        return null;
      };

      const employeeRowClick = (event) => {
        setSelectedEmployee(event.data); // Set selectedEmployee when a row is clicked
        setSidebarVisible(true); // Show the sidebar
      };

      const closeSidebar = () => {
        setSelectedEmployee(null);
        setSidebarVisible(false);
      };


    const openAddCsvPopup = () => {
        setAddCsvPopupOpen(true);
      };
    
      const closeAddCsvPopup = () => {
        setAddCsvPopupOpen(false);
      };

    const openPopup = () => {
      setPopupOpen(true);
    };
  
    const closePopup = () => {
      setPopupOpen(false);
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        Email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        Gender: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const Resps = GenerateRepresentitives(employee)
      const [representatives] = useState(Resps);
    const [statuses] = useState(['Active', 'Retired', 'Fired', 'Resigned', 'Probationary', 'Intern', 'Remote']);

    const getSeverity = (status) => {
        switch (status) {
            case 'Female':
                return 'warning';

            case 'Male':
                return 'success';
        }
    };
    const getSeverityForStatus = (status) => {
        switch (status) {
            case 'Probationary':
                return '';
            case 'Intern':
                return '';
            case 'Remote':
                return '';

            case 'Retired':
                return 'warning';
            case 'Fired':
                return 'danger';
            case 'Resigned':
                return 'info';

            case 'Active':
                return 'success';
        }
    };


    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </span>
            </div>
        );
    };

//employee 
const employeeBodyTemplate = (rowData) =>{
    const name = CheckImageOrLabelForEmployeeTable(rowData)
    return (
        <div className='flex items-center'>
            {rowData.Photo === '' ? 
            <Avatar 
            label={name}
            style={{ backgroundColor: '#32317f', color: '#ffffff' }}
            className="mr-2" size="large" shape="circle" /> : 
            <Avatar 
            image={rowData.Photo}
            className="mr-2" size="large" shape="circle" />}
            <div className="flex flex-col">
                <span className='text-black'>{rowData.Name}</span>
                <span className="text-indigo-500 text-xs">{rowData.Email}</span>
                <span className="text-gray-500 text-xs">{rowData.Phone}</span>
            </div>
        </div>
    );

}
const representativeRowFilterTemplate = (options) => {
    const selectedValues = options.name || [];
    return (
        <MultiSelect
            // value={selectedValues}
            options={representatives}
            itemTemplate={employeeBodyTemplate}
            onChange={(e) => options.filterApplyCallback(e.value)}
            optionLabel="name"
            placeholder="Any"
            className="p-column-filter"
            maxSelectedLabels={1}
            style={{ minWidth: '14rem' }}
        />
    );
};

//Gender
const genderBodyTemplate = (rowData) => {
    return <Tag value={rowData.Gender} severity={getSeverity(rowData.Gender)}/>;
    
};
//Status
const statusItemTemplate = (rowData) => {
    return <Tag value={rowData.EmploymentStatus} severity={getSeverityForStatus(rowData.EmploymentStatus)} />;
};
const statusRowFilterTemplate = (options) => {
    return (
        <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} itemTemplate={statusItemTemplate} placeholder="Select One" className="p-column-filter" showClear style={{ minWidth: '12rem' }} />
    );
};
//Location
const locationTemplate = (rowData) => {  
    return <Tag value={rowData.location}/>;
    
}


const header = renderHeader();
  return (
    
    <div>
    <div className="flex justify-end mb-4">
        <div className='mr-3'>
        <Button disabled={isStartup} label="Import CSV" outlined onClick={openAddCsvPopup}/>
        </div>
        
        <Button disabled={isStartup && employee.length === 9} label="Add Employee"  onClick={openPopup}/>
      </div>
      
      <div>

      
   
      <div className="m-0 bg-white p-4 shadow rounded-lg mt-1">
            <DataTable value={employee} onRowClick={employeeRowClick} removableSort  dataKey='Employee ID' selectionMode="single" selection={selectedEmployee} onSelectionChange={(e) => setSelectedEmployee(e.value)} filters={filters} filterDisplay="row" size={'small'} 
                    globalFilterFields={['Name', 'Department', 'Email', 'Phone']} header={header} emptyMessage="No Employees found 😔">
                <Column field='Employee ID' header="EMPLOYEE ID" filter filterMenuStyle={{ width: '14rem' }}  sortable filterPlaceholder="Search by ID" style={{ minWidth: '12rem' }}/>
                <Column header="EMPLOYEE" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }}  filterPlaceholder="Search by Employee" style={{ minWidth: '12rem' }} body={employeeBodyTemplate} filter filterMatchMode={FilterMatchMode.IN} filterElement={representativeRowFilterTemplate}/>
                <Column field='Department' header="DEPARTMENT" filter sortable filterPlaceholder="Search by Department" style={{ minWidth: '12rem' }} />
                <Column  header="LOCATION" filterPlaceholder="Search by Location" style={{ minWidth: '10rem' }} filter  body={locationTemplate} />
                <Column  header="STATUS" filter showFilterMenu={false} filterPlaceholder="Search by Employement Status" style={{ minWidth: '12rem' }} body={statusItemTemplate} filterElement={statusRowFilterTemplate} />
                <Column field='Gender' header="GENDER" filter filterPlaceholder="Search by Gender" style={{ minWidth: '12rem' }} body={genderBodyTemplate}/>
            </DataTable>
        </div>

      </div>
      {isPopupOpen && <AddEmployeePopUp onClose={closePopup} locations={location} isStartup={isStartup}/>} 
      {isAddCsvPopupOpen && <ImportEmployee onClose={closeAddCsvPopup} />} 
      {sidebarVisible && renderSidebar()}



      </div>
      
      
  )
}

export default EmployeesTable
