import { Avatar } from 'primereact/avatar';
import React, { useEffect, useRef, useState } from 'react';
import { PiDotsThreeCircleLight, PiGearLight } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Transparent from '../Data/IMG/transparent.png';
import { LastTwo, datas } from '../Data/Sidebardata';
import { decryptMydata } from '../Provider/EncriptionAndDecription';
import { CheckImageOrLabelForEmployeeTable } from '../Provider/checkImage';
import { EncryptionAndDecryptionKeys } from '../model/url';
import { clearPersistedData } from '../redux/action';

function Sidebar({isStartup, isPlatinum}) {

  // console.log(isPlatinum)
  const [isOpen, setIsOpen] = useState(false);
  const organization = useSelector((state) => state.or.or);
 

  const key = EncryptionAndDecryptionKeys();

  const decriptedOrganization = JSON.parse(decryptMydata(organization, key)) 
  const name = CheckImageOrLabelForEmployeeTable(decriptedOrganization);
  // console.log(organization)

  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  // show custom setting
  const [show_payroll, Set_show_payroll] = useState(null);
  const [show_leave, Set_show_leave] = useState(null);
  const [show_attendance, Set_show_attendance] = useState(null);
  const [show_total_employees, Set_show_total_employees] = useState(null);



  const getCustomSetting = () =>{
    if (decriptedOrganization && decriptedOrganization.custom_settings) {
      const customSettings = decriptedOrganization.custom_settings;

      // Parse custom settings and set state values accordingly
      Set_show_payroll(customSettings.show_payroll === 'true');
      Set_show_leave(customSettings.show_leave === 'true');
      Set_show_attendance(customSettings.show_attendance === 'true');
      Set_show_total_employees(customSettings.show_total_employees === 'true');
    }
  }






  const LogoutUser = async (e) => {
    dispatch(clearPersistedData());
    localStorage.removeItem('token');
    // window.location.reload();
    navigate("/login");
  }

  useEffect(() => {
    // Function to close the dropdown when a click occurs outside of it

    if (isPlatinum) {
      getCustomSetting();
    }
   
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const location = useLocation();

  return (
    <div className="bg-indigo-900 w-1/6 h-screen pl-8 pr-5 pt-5 flex flex-col">
      <div>
        <img src={Transparent} alt="Token Talent Logo" className='w-auto h-auto max-w-full' />
      </div>
      {
        isPlatinum ? (
          <ul className="py-3">
  {datas.map((data, index) => {
    // Check the custom settings to determine whether to render the item
    // Use the '&&' operator to conditionally render the item
    if (
      (data.to === "/payroll" && !show_payroll) ||
      (data.to === "/leaves" && !show_leave) ||
      (data.to === "/attendance" && !show_attendance) ||
      (data.to === "/employees" && !show_total_employees)
    ) {
      return null; // Do not render this item
    } else {
      return (
        <NavLink to={data.to} key={data.to}>
          <li
            className={`flex items-center p-3 my-1 text-white rounded-xl hover:bg-indigo-700 ${
              location.pathname === data.to ? "bg-indigo-500" : ""
            }`}
          >
            <div className="mr-2 text-lg">{data.icon}</div>
            <div>{data.text}</div>
          </li>
        </NavLink>
      );
    }
  })}
</ul>
        ) : (
          <ul className="py-3">
          {datas.map((data, index) => (
            // Exclude rendering the '/device' item if it's not platinum
            data.to !== "/device" && (
              <NavLink to={data.to} key={data.to}>
                <li
                  className={`flex items-center p-3 my-1 text-white rounded-xl hover:bg-indigo-700 ${
                    location.pathname === data.to ? "bg-indigo-500" : ""
                  }`}
                >
                  <div className="mr-2 text-lg">{data.icon}</div>
                  <div>{data.text}</div>
                </li>
              </NavLink>
            )
          ))}
        </ul>
        )
      }
      {/* {
        isStartup && (
          (
            <ul className="py-3">
         {datas.map((data, index) => (
           <NavLink to={data.to}  key={data.to}>
             <li
               className={`flex items-center p-3 my-1 text-white rounded-xl hover:bg-indigo-700 ${
                 location.pathname === data.to ? 'bg-indigo-500' : ''
               }`}
             >
               <div className="mr-2 text-lg">{data.icon}</div>
               <div>{data.text}</div>
             </li>
           </NavLink>
         ))}
       </ul> 
         )
        )
      } */}
      


      
      
      <div className="flex-grow"></div> {/* Spacer */}
      
   

  <div className='bg-indigo-200 text-black p-3 mb-4 rounded-lg flex items-center justify-between space-x-2 cursor-pointer' onClick={toggleDropdown}>

<div className='flex' >
{decriptedOrganization.Photo === '' ? 
      <Avatar 
      label={name}
      style={{ backgroundColor: '#32317f', color: '#ffffff',  height: '40px', width: '40px' }}
      className="mr-2 w-40 h-40"  shape="circle" /> : 
      <Avatar 
      image={decriptedOrganization.Photo}
      className="mr-2 w-40 h-40" shape="circle" />
        }
 
  <div className='ml-2 flex flex-col justify-center'>
    <span className="truncate w-40 sm:w-20 md:w-25 lg:w-30 xl:w-44 text-ellipsis">{decriptedOrganization.Name}</span>
    <span className="truncate w-40 sm:w-20 md:w-25 lg:w-30 xl:w-40 text-ellipsis">{decriptedOrganization.Email}</span>
  </div>

</div>
      
  {isOpen && (
            <div className="absolute bottom-20  mt-1 bg-white w-1/6 border shadow-lg rounded-lg p-2 z-10 ">
              {LastTwo.map((data, index) => (
                <NavLink to={data.to}  key={data.to}>
                  <li className="flex px-4 py-2 hover:bg-gray-100 text-lg rounded-lg"> <PiGearLight className="mt-1 mr-2 h-5 w-5"/>Account Settings</li>
            
             

                </NavLink>
              ))}
                <div className="border-t border-gray-300"></div>
               <li className="block px-4 py-2 hover:bg-gray-100 text-red-900" onClick={LogoutUser}>Logout</li>
              
            </div>
          )}
  <div>
    <PiDotsThreeCircleLight className='h-7 w-7 text-indigo-900' />
  </div>
</div>

    </div>
  );
}

export default Sidebar;


 {/* <div className='w-10 h-10 sm:h-6 sm:w-6 md:h-7 md:w-7 lg:h-8 lg:w-8 xl:h-10 xl:w-10 rounded-full bg-blue-500 flex items-center justify-center'>
    <span className="text-white font-bold sm:text-sm md:text-base lg:text-lg xl:text-2xl">ZL</span>
  </div> */}
